import React, { useState } from 'react'
import vlad from '../../assets/images/vlad.png';
import venca from '../../assets/images/venca.png';

import styles from './band.module.scss';

import Member from '../../components/member/member';

const Band = () => {
    const [shown, show] = useState(null)

    const vencaContent = (
        <div>
            <h3>bicí, manažer</h3>
            <p>venca@easysteps.cz</p>
        </div>
    );

    const vladContent = (
        <div>
            <h3>zpěv, housle</h3>
            <p>vladimir@easysteps.cz</p>
        </div>
    );

    const showMember = (member) => {
        if (member === shown) {
            show(null);
        } else {
            show(member);
        }
    }

    return (
        <div>
            <div className="p-grid">
                <Member
                    alt="Vladimír"
                    img={vlad}
                    content={vladContent}
                    header="Vladimír"
                    onClick={() => showMember('vlad')}
                    visible={shown === 'vlad'}
                />
                <Member
                    alt="Václav"
                    img={venca}
                    content={vencaContent}
                    header="Venca"
                    onClick={() => showMember('venca')}
                    visible={shown === 'venca'}
                    inverse="true"
                />
            </div>

            <div className={styles.bandContent + " p-mx-auto p-px-3"}>
                <h1>Easy Steps</h1>
                <p></p>
                <p>Kapela fiigurující na české hudební scéně od roku 2014. Od té doby prošla mnoha změnami jak žánrovými, tak personálními. Aktuálně hraje ve čtyřčlenném složení čítajícím mimo dvou stálých členů i dvě nové posily.</p>
            </div>
        </div>
    )
}

export default Band;