import React from 'react';

import { Parallax, Background } from 'react-parallax';

const Video = (props) => {
    let sources;
    let poster;

    if (props.poster) {
        poster = props.poster;
    }

    if (props.sources) {
        sources = (
            props.sources.map(source => {
                return <source key={source.type} src={source.src} type={source.type} />
            })
        )
    }

    if (!poster && !sources) {
        return (null);
    }

    return (
        <Parallax strength={500}
            bgStyle={{
                width: '100%'
            }}
            style={{
                height: 'calc(100vw / 16 * 9)'
            }}>
            <Background>
                <video preload="yes" playsInline autoPlay muted loop width="100%" id={props.id} poster={poster}>
                    {sources}
                </video>
            </Background>
        </Parallax >
    )
}

export default Video;