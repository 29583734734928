import React from 'react';

import { Parallax, Background } from 'react-parallax';

import styles from './titleImage.module.scss';

const TitleImage = (props) => {

    var height = 100

    if (props.height) {
        height = props.height;
    }

    let backgrounds = [];
    const classes = [styles.backgroundImage]
    let max = 1

    if (props.glitch === 'true') {
        max = 5
    } else {
        classes.push(styles.relative)
    }

    for (let i = 1; i <= max; i++) {
        backgrounds.push(<img key={i} alt={props.alt} src={props.image} className={classes.join(" ")} />)
    }

    if (props.spotlight === 'true') {
        backgrounds.push(<div key="spotlight" className={styles.spotlight}></div>)
    }

    return (
        <div className={styles.wrapper}>
            <Parallax strength={500}
                bgStyle={{
                    width: '100%'
                }}
                style={{
                    height: 'calc(' + height + 'vw / 16 * 9)'
                }}
                renderLayer={
                    percentage => (
                        <div className={styles.layer} style={{
                            opacity: 1 - ((percentage - 1) * 1.5)
                        }}>
                            {props.children}
                        </div>
                    )
                }>
                <Background>
                    {backgrounds}
                </Background>
            </Parallax >
        </div>
    );
}

export default TitleImage;