import React from 'react';
import styles from './hamburger.module.scss';

import UnitUtils from '../../../../utils/UnitUtils';

const Hamburger = (props) => {
    var size = 30;
    var unit = 'px';
    if (props.size) {
        [size, unit] = UnitUtils.parseSize(props.size);
    }

    var wrapperStyle = {
        width: size + unit,
        height: size + unit,
        margin: '-' + size / 2 + unit + ' 0 0 -' + size / 2 + unit
    };
    var spanStyle = {
        borderRadius: (size / 10) + unit,
        height: size / 10 + unit
    }

    var offset = size / 3;

    return (
        <div onClick={props.onClick} className={styles.hamburger} style={wrapperStyle}>
            <div className={styles.hamburgerInner}>
                <span style={spanStyle}></span>
                <span style={{ ...spanStyle, top: offset + unit }}></span>
                <span style={{ ...spanStyle, top: (2 * offset) + unit }}></span>
            </div>
        </div>
    );
}

export default Hamburger;