import React from 'react'

const Gallery = props => {
    return (
        <div>
            <div>Dashboard Gallery</div>
        </div>
    )
}

export default Gallery