import axios from 'axios'
import { CodeError } from '../../utils/errors/codeError'

axios.interceptors.response.use(
    response => response,
    async error => {
        if (error.response) {
            const status = error.response.status ? error.response.status : 500
            const message = (error.response.data && error.response.data.message) ? error.response.data.message : unknownError(error)
            throw new CodeError(message, status)
        }
        throw error;
    }
)

const unknownError = e => {
    return "Unknown error"
}

export const callApiGet = async (url, params = {}) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + url, { params: params })
    return response.data

}

export const callApiPost = async (url, request, params = {}) => {
    const response = await axios.post(process.env.REACT_APP_API_URL + url, request, { params: params })
    return response.data
}

export const callApiDelete = async url => {
    return await axios.delete(process.env.REACT_APP_API_URL + url)
}

export const callApiPut = (url, request, params = {}) => {
    const response = axios.put(process.env.REACT_APP_API_URL + url, request, { params: params })
    return response.data
}

export const getQueryParams = params => {
    const result = { ...params }
    if (result.sortField) {
        delete result.sortField
        delete result.sortOrder
        result.sort = params.sortField
        result.direction = params.sortOrder === 1 ? 'asc' : 'desc'
    }

    if (result.filters) {
        delete result.filters
        for (const filter in params.filters) {
            result[filter] = params.filters[filter].value
        }
    }

    return result
}
