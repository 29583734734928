import React from 'react';
import { NavLink } from 'react-router-dom';
import { Easy, Logo, Steps } from '../graphics/graphics';


import styles from './menuLogo.module.scss';

const MenuLogo = () => {
    return (
        <div className="p-d-flex p-jc-start p-jc-sm-center p-ai-center">
            <NavLink to="/">
                <div className={styles.menuLogo}>
                    <div className="p-d-flex p-ai-center p-jc-center">
                        <Easy styles={styles.easy} />
                        <Logo styles={styles.logo} />
                        <Steps styles={styles.steps} />
                    </div>
                </div>
            </NavLink>
        </div>
    );
}

export default MenuLogo;