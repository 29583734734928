import React, { useState, useEffect, useRef } from 'react'
import DataTable from '../../dataTable/dataTable'
import useUsersApi from '../../../service/api/userApi'

import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { Avatar } from 'primereact/avatar'
import { Toast } from 'primereact/toast'

import Form from '../../../containers/form/form'

import { ROLES } from '../../../service/auth/auth'

import { CodeError } from '../../../utils/errors/codeError'

import styles from './users.module.scss'
import CreateButton from '../components/buttons/createButton/createButton'
import ConfirmButton from '../components/buttons/confirmButton/confirmButton'
import DeclineButton from '../components/buttons/declineButton/declineButton'

const Users = props => {
    const api = useUsersApi()

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState(null)
    const [user, setUser] = useState(null)
    const [lazyParams, setLazyParams] = useState({})
    const [first, setFirst] = useState(0)
    const [originalRows] = useState({})
    const [userDialog, setUserDialog] = useState(false)
    const [deleteUserDialog, setDeleteUserDialog] = useState(false)

    const [version, setVersion] = useState(0)

    const [submitting, setSubmitting] = useState(false)

    const formRef = useRef()
    const toast = useRef(null)

    const formConfig = {
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Email uživatele'
            },
            validation: {
                required: {
                    error: 'Bez emailu by to nešlo!'
                },
                email: {
                    error: 'Takhle emailová adresa nevypadá!'
                }
            }
        }
    }

    const avatarTemplate = value => {
        return (<Avatar className={styles.avatar}
            image={value} shape="circle"
        />)
    }

    const roleTemplate = value => {
        const roles = value ? value.split(',') : []

        return (<div className={styles.roles}>
            {
                roles.map(role => {
                    return (<div className="role" key={role}>{ROLES[role].label}</div>)
                })
            }
        </div>)
    }

    const rolesItems = Object.keys(ROLES).map(role => {
        const option = {
            label: ROLES[role].label, value: ROLES[role].code
        }
        return option
    })

    const roleEditor = props => {
        return (
            <MultiSelect
                className={styles.rolesSelector}
                value={props.rowData.role.roles ? props.rowData.role.roles.split(',') : null}
                options={rolesItems}
                onChange={(e) => onEditorValueChange(props, e.target.value)}
            />
        )
    }

    const columns = [
        { field: 'avatar', label: 'Avatar', sortable: false, body: avatarTemplate },
        { field: 'id', label: 'ID', sortable: true },
        { field: 'name', label: 'Jméno', sortable: true, filter: true },
        { field: 'surname', label: 'Příjmení', sortable: true, filter: true },
        { field: 'email', label: 'Email', sortable: true, filter: true },
        { field: 'role.roles', label: 'Role', body: roleTemplate, editor: roleEditor }
    ]

    const onRowEditInit = event => {
        originalRows[event.index] = { ...users[event.index] }
        originalRows[event.index].role = { ...users[event.index].role }
    }

    const onRowEditCancel = event => {
        let canceledUsers = [...users]

        canceledUsers[event.index] = originalRows[event.index]
        delete originalRows[event.index]

        setUsers(canceledUsers)
    }

    const onRowEditSave = event => {
        setLoading(true)

        api.updateRole(users[event.index].role)
            .then(data => {
                setLoading(false)
                showSuccess('Role změněny')
            })
            .catch(error => {
                if (error.response) {
                    showFailure(error.response)
                    throw new CodeError(error.response.message, error.response.status)
                }
                showFailure(JSON.stringify(error))
                throw new CodeError("Unknown error: " + JSON.stringify(error), 500)
            })
    }

    const onEditorValueChange = (props, value) => {
        let updatedUsers = [...props.value]
        getPropertySetter(updatedUsers[props.rowIndex], props.field)(value.join(","))
        setUsers(updatedUsers)
    }

    const getPropertySetter = (object, property) => {
        var obj = object
        var parent = null
        var lastProp = null
        property.split(".").forEach(prop => {
            parent = obj
            obj = obj[prop]
            lastProp = prop
        })

        return val => parent[lastProp] = val
    }

    useEffect(() => {
        setLoading(true);

        api.getAll(lazyParams).then(data => {
            setUsers(data)
            setLoading(false)
        })
    }, [lazyParams, api, version])

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event }

        console.log("Setting lazy params:" + JSON.stringify(_lazyParams))
        setLazyParams(_lazyParams)
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event }
        setFirst(0)
        setLazyParams(_lazyParams)
    }

    const onPage = (event) => {
        setFirst(event.first)
    }

    const openNew = () => {
        setUserDialog(true)
    }

    const hideDialog = () => {
        setUserDialog(false)
    }

    const saveUser = () => {
        setSubmitting(true)
        formRef.current.triggerSubmit().finally(() => {
            setSubmitting(false)
            setVersion(version + 1)
            setUserDialog(false)
        })
    }

    const deleteUserConfirm = user => {
        setUser(user)
        setDeleteUserDialog(true)
    }

    const submit = formData => {
        return api.createUser(formData.email.value)
    }

    const userDialogFooter = (
        <React.Fragment>
            <DeclineButton onClick={hideDialog} submitting={submitting} />
            <ConfirmButton onClick={saveUser} submitting={submitting} />
        </React.Fragment>
    )

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false)
        setUser(null)
    }

    const deleteUser = () => {
        setSubmitting(true)

        api.deleteUser(user.id).then(result => {
            showSuccess('Uživatel smazán')
        }).catch(error => {
            showFailure(error.message)
        }).finally(() => {
            setSubmitting(false)
            setUser(null)
            setVersion(version + 1)
            setDeleteUserDialog(false)
        })
    }

    const deleteUserDialogFooter = (
        <React.Fragment>
            <DeclineButton label="Ne" className="p-button-warning" onClick={hideDeleteUserDialog} submitting={submitting} />
            <ConfirmButton label="Ano" className="p-button-danger" onClick={deleteUser} submitting={submitting} />
        </React.Fragment>
    )

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <CreateButton onClick={openNew} />
            </React.Fragment>
        )
    }

    const showSuccess = msg => {
        toast.current.show({ severity: 'success', summary: 'Cajk', detail: msg, life: 3000 })
    }

    const showFailure = msg => {
        toast.current.show({ severity: 'error', summary: 'Chyba', detail: msg, life: 3000 })
    }

    return (
        <div className={styles.content}>
            <div className="p-card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                <DataTable data={users}
                    lazyParams={lazyParams}
                    first={first}
                    onSort={onSort}
                    onFilter={onFilter}
                    loading={loading}
                    onPage={onPage}
                    columns={columns}
                    editMode="row"
                    dataKey="id"
                    onRowEditInit={onRowEditInit}
                    onRowEditCancel={onRowEditCancel}
                    onRowEditSave={onRowEditSave}
                    onDeleteRow={deleteUserConfirm}
                />
            </div>

            <Dialog visible={userDialog} style={{ width: '450px' }} header="Nový Uživatel" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>

                <Form
                    ref={formRef}
                    config={formConfig}
                    onSubmit={formData => submit(formData)}
                    submitting={submitting}
                    onSuccess={showSuccess}
                    onFailure={showFailure}
                />

            </Dialog>
            <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header="Opravdu?" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                <div className={styles.confirmationContent}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {user && <span>Určitě chceš smazat uživatele <b>{user.name + " " + user.surname}</b>?</span>}
                </div>
            </Dialog>
            <Toast ref={toast} />
        </div>
    );
}



export default Users