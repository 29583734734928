import { ROLES } from '../service/auth/auth'

class JWT {
    constructor(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        this.payload = JSON.parse(jsonPayload)

        if (this.payload.role) {
            this.roles = this.payload.role.split(",").map(role => role.trim())
        } else {
            this.roles = []
        }

        this.token = token
    }

    isRole(role) {
        return this.roles.includes(role.code) || this.isSU()
    }

    isSU() {
        return this.roles.includes(ROLES.SU.code)
    }

    isGigsAdmin() {
        return this.isSU || this.isRole(ROLES.GIGS_ADMIN.code)
    }

    isGalleryAdmin() {
        return this.isSU || this.isRole(ROLES.GALLERY_ADMIN.code)
    }

    isBlogAdmin() {
        return this.isSU || this.isRole(ROLES.BLOG_ADMIN.code)
    }

    isExpired() {
        return this.payload.exp * 1000 <= Date.now()
    }
}

export default JWT