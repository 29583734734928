import React from 'react'
import styles from './banner.module.scss'

import { NavLink } from 'react-router-dom';

import FlashyButton from '../UI/buttons/flashyButton/flashyButton';

import { Parallax, Background } from 'react-parallax';

const Banner = props => {
    return (
        <div className={styles.wrapper}>
            <Parallax strength={100}
                bgStyle={{
                    width: '100%'
                }}
            >
                <Background>
                    <img alt="background" className={styles.backgroundImg} src={props.background} />
                </Background>
                <div className={props.className + " " + styles.contentWrapper} >
                    <div className={styles.titlesSection}>
                        {props.title &&
                            <h1 className={styles.title}>{props.title}</h1>
                        }
                        {props.subtitle &&
                            <h2 className={styles.subTitle}>{props.subtitle}</h2>
                        }
                    </div>
                    {props.buttonLabel && props.link &&
                        <NavLink to={props.link}>
                            <FlashyButton label={props.buttonLabel} />
                        </NavLink>
                    }
                </div>
            </Parallax >
        </div>

    )
}

export default Banner;