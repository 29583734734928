import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom"
import ProgressButton from '../../buttons/progressButton/progressButton'

import { Auth } from '../../../service/auth/auth'

import styles from './login.module.scss'
import bg from '../../../assets/images/admin/login-bg.jpg';
import logo from '../../../assets/vectors/logo/es_logo_name_white.svg'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Messages } from 'primereact/messages'

const Login = props => {
    const history = useHistory()
    const msgs = useRef(null)

    const [logging, setLogging] = useState(false)

    useEffect(() => {
        if (Auth.currentTokenValue) {
            history.push("/admin")
        }
    }, [history])


    return (
        <div className={styles.wrapper + " p-d-flex p-flex-column p-jc-center p-ai-center"}>
            <div className={styles.background} style={{ backgroundImage: `url(${bg})` }} />
            <img className={styles.logo} alt="Easy Steps Logo" src={logo}></img>
            <div className="p-d-flex p-flex-row">
                <ProgressButton
                    onClick={async event => {
                        setLogging(true)
                        try {
                            await Auth.login()
                            // get return url from location state or default to home page
                            const { from } = history.location.state || { from: { pathname: "/admin" } }
                            history.push(from)
                        } catch (error) {
                            await Auth.logout()
                            msgs.current.show({
                                severity: 'error',
                                summary: error.code ? error.code : "",
                                detail: error.message ? error.message : error,
                                sticky: true
                            })
                            setLogging(false)
                        }
                    }}
                    disabled={logging}
                    className={styles.facebookBtn}>
                    <div className={styles.buttonLabel}>
                        <FontAwesomeIcon icon={faFacebookF} />
                        <span>Přihlas se Facebookem</span>
                        <div></div>
                    </div>
                </ProgressButton>
            </div>
            <div className="p-d-flex p-flex-row">
                <Messages ref={msgs} />
            </div>
        </div>
    )
}

export default Login