import React from 'react'

import { Menu } from 'primereact/menu';
import { useHistory } from "react-router-dom"

import { Auth } from '../../../../service/auth/auth'

import styles from './user-menu.module.scss'

const UserMenu = props => {
    const history = useHistory()

    const items = [
        {
            disabled: true,
            template: (item, options) => {
                return (
                    <div className={styles.header + " " + options.className}>
                        <div className={options.labelClassName + " " + styles.name}>{Auth.currentAccountValue.user.name}</div>
                        <div className={styles.email}>{Auth.currentAccountValue.user.email}</div>
                    </div>
                );
            }
        },
        {
            label: 'Odhlásit',
            icon: 'pi pi-fw pi-power-off',
            command: () => { history.push("/logout") }
        }
    ]

    return (
        <Menu model={items} popup ref={props.menuRef} />
    )
}

export default UserMenu