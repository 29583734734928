import React from 'react'
import styles from './snow.module.scss'

const Snow = () => {
    const today = new Date()
    const show = (today.getMonth() === 11) || (today.getMonth() === 0 && today.getDate() <= 6)


    const flakesCount = show ? 50 : 0

    const flake = (<div className={styles.snowflake}></div>)
    var flakes = []

    for (let i = 0; i < flakesCount; i++) {
        flakes.push(flake)
    }

    return (
        <div className={styles.snow}>
            {flakes}
        </div>
    )
}

export default Snow