import React, { useState, useEffect } from 'react';

import TitleImage from '../titleImage/titleImage';
import Section from './section/section.tsx';

import { TabView, TabPanel } from 'primereact/tabview';

import useGigsApi from '../../service/api/gigsApi';

import styles from './gigs.module.scss';

import image from '../../assets/images/vlad_venca2.jpg';

const Gigs = () => {
    const api = useGigsApi();

    const [upcomingGigs, setUpcomingGigs] = useState([])
    const [upcomingGigsLoading, setUpcomingGigsLoading] = useState(true)
    const [upcomingGigsError, setUpcomingGigsError] = useState(false)

    const [passedGigs, setPassedGigs] = useState([])
    const [passedGigsLoading, setPassedGigsLoading] = useState(true)
    const [passedGigsError, setPassedGigsError] = useState(false)

    useEffect(() => {
        api.getUpcomingGigs().then(result => {
            setUpcomingGigsLoading(false)
            setUpcomingGigsError(false)
            setUpcomingGigs(result)
        }).catch(error => {
            console.error(error.message);
            setUpcomingGigsLoading(false)
            setUpcomingGigsError(true)
            setUpcomingGigs([])
        });

        api.getPassedGigs().then(result => {
            setPassedGigsLoading(false)
            setPassedGigsError(false)
            setPassedGigs(result)
        }).catch(error => {
            console.error(error.message);
            setPassedGigsLoading(false)
            setPassedGigsError(true)
            setPassedGigs([])
        });
    }, [api]);

    return (<div className={styles.wrapper}>
        <TitleImage image={image} alt="Koncerty" height="80" glitch="true">
            {getSvg(0, "#00000000")}
            {/*getSvg(1, "#00ffea")*/}
            {/*getSvg(2, "#fe3a7f")*/}
        </TitleImage>
        <TabView>
            <TabPanel header="Nadcházející">
                <Section gigs={upcomingGigs} loading={upcomingGigsLoading} error={upcomingGigsError} emptyMsg="Aktuálně nejsou naplánované žádné koncerty" />
            </TabPanel>
            <TabPanel header="Minulé">
                <Section gigs={passedGigs} loading={passedGigsLoading} error={passedGigsError} emptyMsg="Žádné proběhlé koncerty nejsou k dispozici" />
            </TabPanel>
        </TabView>
    </div>);
}

const getSvg = (counter, shadowColor) => {
    const filterName = "shadow" + counter
    const style = { filter: "url(#" + filterName + ")" }

    return (<svg className={styles.text + " " + styles["text" + counter]} alt="Koncerty" data-name="Koncerty" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1240.22 290.75">
        <filter id={filterName}>
            <feDropShadow dx="-3" dy="0" stdDeviation="0.5"
                floodColor={shadowColor} />
        </filter>
        <title>koncerty</title>
        <g style={style} >
            <polygon points="75.51 127.25 130.93 0.03 83.74 0.03 44.32 86.46 44.32 0.03 0 0.03 0 290.03 44.32 290.03 44.32 170.99 91.74 290.03 114.86 290.03 138.93 290.03 75.51 127.25" />
            <path d="M311.54,392.94v-.17h-33.1v.17c-42,2.15-62.82,27.08-62.77,71.2l.92,118.73v28.52c0,43.78,20.35,68.64,61.74,71.15v.23h33.32v-.23c41.39-2.51,61.74-27.37,61.74-71.15V582.87l.92-118.73C374.36,420,353.54,395.09,311.54,392.94Zm-3.06,39.94c14.43,0,22.67,6.61,22.67,27.22L330,618.2l.14-.08c0,20.63-8.26,27.22-22.67,27.22h-25c-14.41,0-22.67-6.59-22.67-27.22l.14.08-1.12-158.1c0-20.61,8.24-27.22,22.67-27.22Z" transform="translate(-79.87 -392.46)" />
            <polygon points="440.72 0.31 418.56 16.31 418.56 179.95 357.68 0.31 313.36 0.31 313.36 290.31 357.68 290.31 357.68 134.51 418.56 290.31 462.88 290.31 462.88 16.31 440.72 0.31" />
            <path d="M633,682.77c36.25,0,58.53-14.42,67-41.31A79.52,79.52,0,0,0,705,612.53V595.65l-23.16-16.09-22.39,16.09v20c0,19.75-11.47,26.89-26.5,27.07-15-.18-26.5-7.32-26.5-27.07L609,460.26c0-20,8.62-27.47,23.92-27.47s27.47,7.47,27.47,27.47v11.37h41.94v-9c0-44.34-21.6-69.84-68.67-69.84s-71,25.5-71,69.84l-1.67,149.92c0,45,31,70.11,72,70.24Z" transform="translate(-79.87 -392.46)" />
            <polygon points="638.52 290.06 760.31 290.06 760.31 247.37 682.84 247.37 682.84 163.71 728.41 163.71 744.98 142.16 728.41 121.02 682.84 121.02 682.84 42.31 760.31 42.31 760.31 0.06 638.52 0.06 638.52 290.06" />
            <path d="M1011.4,682.83,963.59,566.15c22.12-10.42,33-32.34,33-64.42V464.21c.06-46.2-22.75-71.38-68.81-71.38H860.58v290h44.08V573.1H920.5l43.71,109.73ZM904.77,432.94h25c14.44,0,22.67,6.61,22.67,27.22v42.06l.14-.08c0,20.63-8.26,27.22-22.67,27.22H904.77Z" transform="translate(-79.87 -392.46)" />
            <polygon points="924.76 0 924.76 44.32 980.28 44.32 980.28 290 1024.61 290 1024.61 44.32 1073.91 44.32 1088.4 21.94 1073.91 0 924.76 0" />
            <polygon points="1165.9 126.77 1132.78 0.75 1087.55 0.75 1142.49 177.67 1142.49 276.21 1163.88 290.75 1185.7 276.21 1185.7 177.67 1240.22 0.75 1199.03 0.75 1165.9 126.77" />
        </g>
    </svg>);
}

export default Gigs;