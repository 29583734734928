import React, { useState, useEffect, useRef } from 'react'
import Logo from '../../menuLogo/menuLogo'
import { Avatar } from 'primereact/avatar'
import { TabMenu } from 'primereact/tabmenu'
import { Menu } from 'primereact/menu'

import UserMenu from '../menu/user/user-menu'
import { useHistory, useLocation } from "react-router-dom"

import { Auth, ROLES } from '../../../service/auth/auth'

import styles from './navbar.module.scss'

const Navbar = props => {
    const history = useHistory()
    const location = useLocation()

    const userMenuRef = useRef(null)
    const menuRef = useRef(null)

    const getItems = token => {
        return [
            {
                label: 'Koncerty',
                icon: 'fa fa-music',
                path: '/admin/koncerty',
                disabled: !token || !token.isRole(ROLES.GIGS_ADMIN),
                command: () => { history.push('/admin/koncerty') }
            },
            {
                label: 'Místa',
                icon: 'fa fa-map-marked-alt',
                path: '/admin/mista',
                disabled: !token || !token.isRole(ROLES.GIGS_ADMIN),
                command: () => { history.push('/admin/mista') }
            },
            {
                label: 'Galerie',
                icon: 'fa fa-images',
                path: '/admin/galerie',
                disabled: !token || !token.isRole(ROLES.GALLERY_ADMIN),
                command: () => { history.push('/admin/galerie') }
            },
            // {
            //     label: 'Blog',
            //     icon: 'fa fa-newspaper',
            //     path: '/admin/blog',
            //     disabled: !Auth.isRole(ROLES.BLOG_ADMIN),
            //     command: () => { history.push('/admin/blog') }
            // },
            {
                label: 'Uživatelé',
                icon: 'fa fa-users',
                path: '/admin/borci',
                disabled: !token || !token.isRole(ROLES.SU),
                command: () => { history.push('/admin/borci') }
            }
        ]
    }

    const [items, setItems] = useState(getItems(Auth.currentAccountValue ? Auth.currentAccountValue.token : null))
    const [avatar, setAvatar] = useState(Auth.currentAccountValue ? Auth.currentAccountValue.user.avatar : null)


    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        const active = items.findIndex(item => item.path === location.pathname)

        // timeout is a workaround for misaligned underscore of active menu item
        const timer = setTimeout(() => {
            setActiveIndex(active)
        }, 100)

        return () => {
            clearTimeout(timer)
        }
    }, [items, location])

    useEffect(() => {
        const subscription = Auth.currentAccount.subscribe(account => {
            if (account) {
                setItems(getItems(account.token))
                setAvatar(account.user.avatar)
            } else {
                setItems(getItems(null))
                setAvatar(null)
            }
        })

        return () => {
            subscription.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className="p-d-flex p-flex-row">
                <div className={styles.logo}>
                    <Logo />
                </div>
                <Menu model={items} popup ref={menuRef} id="popup_menu" />
                <div className={styles.menuButton} onClick={(event) => menuRef.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>
                    <i className="pi pi-bars"></i>
                </div>
                <TabMenu
                    model={items}
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                        setActiveIndex(e.index)
                    }}
                />
                <div className={styles.stretch} />
                <Avatar className={styles.avatar}
                    image={avatar} shape="circle"
                    onClick={(event) => userMenuRef.current.toggle(event)}
                />
                <UserMenu menuRef={userMenuRef} />
            </div>
        </div>
    )
}

export default Navbar;