import { useJsApiLoader } from '@react-google-maps/api';

const libraries = ["places"]

const useGoogleMaps = () => {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: libraries
    })

    return [isLoaded, loadError];
}



export default useGoogleMaps;