import React, { useState } from 'react'
import styles from './dataTable.module.scss'

import { DataTable as PDataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

const DataTable = props => {
    const rows = props.rows ? props.rows : 10

    const lazyParams = props.lazyParams

    const [editingRows, setEditingRows] = useState({})

    const columnTemplate = (title, columnData, body) => {
        const bodyContent = body ? body(columnData) : columnData
        return (
            <React.Fragment>
                <span className="p-column-title">{title}</span>
                {bodyContent}
            </React.Fragment>
        );
    }

    const stopRowEditing = row => {
        let _editingRows = { ...editingRows }
        if (_editingRows[row[props.dataKey]]) {
            delete _editingRows[row[props.dataKey]]
        }
        setEditingRows(_editingRows)
    }

    const getProperty = (object, property) => {
        var obj = object
        property.split(".").forEach(prop => {
            if (obj != null) {
                obj = obj[prop]
            }
        })

        return obj
    }

    const onDeleteRow = row => {
        if (props.onDeleteRow) {
            props.onDeleteRow(row)
        }
    }

    const editRow = event => {
        props.onRowEditInit(event)

        if (props.editMode === 'row') {
            let _editingRows = { ...editingRows, ...{ [`${event.data[props.dataKey]}`]: true } }
            setEditingRows(_editingRows)
        }
    }

    const saveRow = event => {
        if (props.editMode === 'row') {
            stopRowEditing(event.data)
        }

        props.onRowEditSave(event)
    }

    const actionBodyTemplate = (rowData) => {
        var editing = editingRows[rowData[props.dataKey]]
        const event = { index: findIndexByKey(rowData), data: rowData }
        var body = editing ?
            (<React.Fragment>
                <Button icon="pi pi-check" className="p-button-rounded p-button-success p-mr-2" onClick={() => saveRow(event)} />
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => { stopRowEditing(rowData); props.onRowEditCancel(event) }} />
            </React.Fragment>) :
            (<React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editRow(event)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => onDeleteRow(rowData)} />
            </React.Fragment>)

        return body
    }

    const findIndexByKey = row => {
        const key = row[props.dataKey]
        let index = -1;
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i][props.dataKey] === key) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onRowEditChange = (event) => {
        setEditingRows(event.data);
    }

    return (
        <div className={styles.wrapper}>
            <PDataTable value={props.data ? props.data.slice(props.first, props.first + rows) : []}
                lazy
                paginator
                first={props.first}
                rows={rows}
                totalRecords={props.data ? props.data.length : 0}
                onPage={props.onPage}
                onSort={props.onSort}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onFilter={props.onFilter}
                filters={lazyParams.filters}
                loading={props.loading}
                editMode={props.editMode}
                dataKey={props.dataKey}
                onRowEditInit={props.onRowEditInit}
                onRowEditCancel={props.onRowEditCancel}
                onRowEditSave={props.onRowEditSave}
                editingRows={editingRows}
                onRowEditChange={onRowEditChange}>
                {
                    props.columns.map(column => {
                        return (<Column field={column.field}
                            header={column.label}
                            sortable={column.sortable}
                            filter={column.filter}
                            body={row => columnTemplate(column.label, getProperty(row, column.field), column.body)}
                            filterPlaceholder="Hledat"
                            key={column.field}
                            editor={column.editor}
                            editorValidator={column.editorValidator}
                        />)
                    })
                }
                {
                    props.editMode &&

                    <Column body={actionBodyTemplate} />
                    // <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} />

                }
            </PDataTable>
        </div>
    )
}

export default DataTable