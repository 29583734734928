import { createContext, useContext } from 'react'
import { callApiGet, callApiPost, callApiPut, callApiDelete, getQueryParams } from './api'


const createUser = (email) => {
    return callApiPost("/users/create", { email: email })
}

const deleteUser = (id) => {
    return callApiDelete("/users/" + id)
}

const getAllUsers = (params = {}) => {
    return callApiGet("/users", getQueryParams(params))
}

const updateRole = (role) => {
    return callApiPut("/users/" + role.user_id + "/role", { "roles": role.roles })
}

const UsersApiContext = createContext({
    getAll: getAllUsers,
    updateRole: updateRole,
    createUser: createUser,
    deleteUser: deleteUser
})

export const useUsersApi = () => {
    return useContext(UsersApiContext)
}

export default useUsersApi