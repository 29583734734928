import React from 'react'
import styles from './progressButton.module.scss'
import { Button } from 'primereact/button'
import InfiniteProgress from '../../UI/infiniteProgress/infiniteProgress'

const ProgressButton = props => {
    return (
        <Button className={styles.button + " " + props.className} onClick={props.onClick} disabled={props.disabled}>
            <div className={styles.spacer} />
            <div className={styles.label}>{props.children}</div>
            <InfiniteProgress size="2rem" className={!props.disabled && styles.hidden} />
        </Button>
    )
}

export default ProgressButton