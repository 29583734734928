import React, { useState, useEffect, useRef } from 'react'
import DataTable from '../../dataTable/dataTable'
import useGigsApi from '../../../service/api/gigsApi'
import useLocationsApi from '../../../service/api/locationApi'
import Lineup from '../../gigs/gig/lineup/lineup'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'

import Form from '../../../containers/form/form'

import styles from './gigs.module.scss'
import { Tooltip } from 'primereact/tooltip'

import ConfirmButton from '../components/buttons/confirmButton/confirmButton'
import DeclineButton from '../components/buttons/declineButton/declineButton'
import { Toolbar } from 'primereact/toolbar'
import CreateButton from '../components/buttons/createButton/createButton'

const Gigs = props => {
    const api = useGigsApi()
    const locationsApi = useLocationsApi()

    const emptyGig = {
        id: null,
        name: '',
        date: '',
        time: null,
        admission: null,
        stage: null,
        lineup: null,
        facebook_event: null,
        web: null,
        canceled: false,
        location: {
            id: 1,
            name: 'Neznámé'
        }
    }

    const [loading, setLoading] = useState(false)
    const [gigs, setGigs] = useState(null);
    const [lazyParams, setLazyParams] = useState({ sortField: 'date', sortOrder: -1 })
    const [first, setFirst] = useState(0)
    const [gig, setGig] = useState(null);
    const [gigDialog, setGigDialog] = useState(false)
    const [deleteGigDialog, setDeleteGigDialog] = useState(false)
    const [version, setVersion] = useState(0)

    const formRef = useRef(null)
    const toast = useRef(null)
    const [submitting, setSubmitting] = useState(false)

    const [formConfig, setFormConfig] = useState({
        id: {
            elementType: 'input',
            elementConfig: {
                type: 'hidden'
            }
        },
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Name'
            },
            validation: {
                required: {
                    error: 'Bezejmenných akcí se neúčastníme'
                }
            }
        },
        date: {
            elementType: 'date',
            elementConfig: {
                label: 'Datum'
            },
            validation: {
                required: {
                    error: 'No ale kdy?'
                }
            }
        },
        time: {
            elementType: 'time',
            elementConfig: {
                label: 'Čas'
            }
        },
        location: {
            elementType: 'dropdown',
            elementConfig: {
                label: 'Místo',
                options: [],
                filter: true
            },
            validation: {
                required: {
                    error: 'No ale kde?'
                }
            }
        },
        admission: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Vstupné'
            }
        },
        stage: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Stage'
            }
        },
        lineup: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Lineup'
            }
        },
        facebook_event: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Facebook'
            }
        },
        web: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Web'
            }
        },
        canceled: {
            elementType: 'checkbox',
            elementConfig: {
                label: 'Zrušeno'
            }
        }
    })

    const makeid = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < 25; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const lineupTemplate = value => {
        if (value) {
            const id = makeid()
            return (<React.Fragment>
                <Tooltip target={"#" + id}>
                    <Lineup lineupJSON={value} />
                </Tooltip>
                <div className={styles.lineupIcon}>
                    <i id={id} className={"pi pi-file " + styles.lineupIcon}></i>
                </div>
            </React.Fragment>)
        }

        return (<div></div>)
    }

    const timeTemplate = value => {
        return value ? value.substring(0, value.length - 3) : value
    }

    const dateTemplate = value => {
        return new Date(value).toLocaleDateString("cs-CZ", { year: 'numeric', month: 'numeric', day: 'numeric' })
    }

    const canceledTemplate = value => {
        return value === 0 ? (<div></div>) : (<i className={"pi pi-ban " + styles.canceled}></i>)
    }

    const webTemplate = value => {
        if (value) {
            const id = makeid()
            return (<React.Fragment>
                <Tooltip target={"#" + id}>{value}</Tooltip>
                <a id={id} className={styles.web} href={value}><i className="pi pi-globe"></i></a>
            </React.Fragment>)
        }
        return (<div></div>)
    }

    const facebookTemplate = value => {
        if (value) {
            const id = makeid()
            return (<React.Fragment>
                <Tooltip target={"#" + id}>{value}</Tooltip>
                <a id={id} className={styles.facebook} href={value}><i className="pi pi-facebook"></i></a>
            </React.Fragment>)
        }
        return (<div></div>)
    }

    const onRowEditInit = event => {
        const _gig = {}

        Object.keys(formConfig).forEach(key => {
            if (key === 'location') {
                _gig[key] = { value: event.data[key] ? { id: event.data[key].id, name: event.data[key].name } : {} }
            } else if (key === 'date') {
                _gig[key] = { value: event.data[key] ? new Date(event.data[key]) : new Date() }
            } else if (key === 'time') {
                _gig[key] = { value: event.data[key] ? new Date("0001-01-01T" + event.data[key]) : null }
            } else if (key === 'canceled') {
                _gig[key] = { value: event.data[key] ? !!event.data[key] : false }
            } else {
                _gig[key] = { value: event.data[key] ? event.data[key] : '' }
            }
        })

        setGig(_gig)
        setGigDialog(true)
    }

    const hideDialog = () => {
        setGig(null)
        setGigDialog(false)
    }

    const saveGig = () => {
        setSubmitting(true)
        formRef.current.triggerSubmit()
            .then(() => {
                setGigDialog(false)
                setGig(null)
            }).catch(error => {
                if (!error) {
                    return
                }
            }).finally(() => {
                setSubmitting(false)
            })
    }

    const submit = formData => {
        const data = {}

        Object.keys(formData).forEach(input => {
            data[input] = normalizeInputValue(input, formData[input].value)
        })

        data.location_id = data.location.id
        delete data.location

        if (data.id) {
            return api.update(data).then(result => {
                setVersion(version + 1)
                return result
            }).then(data => {
                showSuccess('Koncert upraven')
                return data
            })
        } else {
            return api.create(data).then(result => {
                setVersion(version + 1)
                return result
            }).then(data => {
                showSuccess('Koncert vytvořen')
                return data
            })
        }
    }


    const normalizeInputValue = (key, value) => {
        if (typeof value === 'string') {
            if (/\S/.test(value)) {
                return value.trim()
            } else {
                return null
            }
        } else if (typeof value === 'boolean') {
            return value ? 1 : 0
        } else if (key === 'date') {
            return value.getFullYear() + "-" + ("0" + (value.getMonth() + 1)).slice(-2) + "-" + ("0" + value.getDate()).slice(-2)
        } else if (key === 'time') {
            return value ? (("0" + value.getHours()).slice(-2) + ":" + ("0" + value.getMinutes()).slice(-2) + ":00") : null
        }

        return value
    }

    const gigDialogFooter = (
        <React.Fragment>
            <DeclineButton onClick={hideDialog} submitting={submitting} />
            <ConfirmButton onClick={saveGig} submitting={submitting} />
        </React.Fragment>
    )

    const columns = [
        { field: 'id', label: 'ID', sortable: true },
        { field: 'name', label: 'Název', sortable: true, filter: true },
        { field: 'date', label: 'Datum', sortable: true, body: dateTemplate },
        { field: 'time', label: 'Čas', body: timeTemplate },
        { field: 'admission', label: 'Vstupné' },
        { field: 'stage', label: 'Stage' },
        { field: 'lineup', label: 'Lineup', body: lineupTemplate },
        { field: 'facebook_event', label: 'Facebook', body: facebookTemplate },
        { field: 'web', label: 'Web', body: webTemplate },
        { field: 'canceled', label: 'Zrušeno', body: canceledTemplate },
        { field: 'location.name', label: 'Místo', sortable: true, filter: true }
    ]

    useEffect(() => {
        setLoading(true);

        locationsApi.getAll().then(data => {
            const _locations = data.map(location => {
                return { label: location.name, value: { id: location.id, name: location.name } }
            })

            const _formConfig = { ...formConfig }
            _formConfig.location.elementConfig.options = _locations
            setFormConfig(_formConfig)
        })

        api.getAll(lazyParams).then(data => {
            setGigs(data)
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams, api, locationsApi, version])

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event }
        setLazyParams(_lazyParams)
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event }
        setFirst(0)
        setLazyParams(_lazyParams)
    }

    const onPage = (event) => {
        setFirst(event.first)
    }

    const showSuccess = msg => {
        toast.current.show({ severity: 'success', summary: 'Cajk', detail: msg, life: 3000 })
    }

    const showFailure = msg => {
        toast.current.show({ severity: 'error', summary: 'Chyba', detail: msg, life: 3000 })
    }

    const openNew = () => {
        onRowEditInit({ data: emptyGig })
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <CreateButton onClick={openNew} />
            </React.Fragment>
        )
    }

    const deleteGigConfirm = gig => {
        setGig(gig)
        setDeleteGigDialog(true)
    }

    const hideDeleteGigDialog = () => {
        setDeleteGigDialog(false)
        setGig(null)
    }

    const deleteGig = () => {
        setSubmitting(true)

        api.delete(gig.id).then(result => {
            showSuccess('Koncert smazán')
        }).catch(error => {
            showFailure(error.message)
        }).finally(() => {
            setSubmitting(false)
            setGig(null)
            setVersion(version + 1)
            setDeleteGigDialog(false)
        })
    }

    const deleteGigDialogFooter = (
        <React.Fragment>
            <DeclineButton label="Ne" className="p-button-warning" onClick={hideDeleteGigDialog} submitting={submitting} />
            <ConfirmButton label="Ano" className="p-button-danger" onClick={deleteGig} submitting={submitting} />
        </React.Fragment>
    )

    return (
        <div className={styles.content}>
            <div className="p-card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                <DataTable data={gigs}
                    lazyParams={lazyParams}
                    first={first}
                    onSort={onSort}
                    onFilter={onFilter}
                    loading={loading}
                    onPage={onPage}
                    columns={columns}
                    editMode="dialog"
                    dataKey="id"
                    onRowEditInit={onRowEditInit}
                    onDeleteRow={deleteGigConfirm}
                />
            </div>
            <Dialog visible={gigDialog} style={{ width: '450px' }} header="Koncert" modal className="p-fluid" footer={gigDialogFooter} onHide={hideDialog}>
                <div className={styles.gigDialogContent}>
                    <Form
                        ref={formRef}
                        config={formConfig}
                        onSubmit={formData => submit(formData)}
                        submitting={submitting}
                        formData={gig}
                        onFailure={showFailure}
                    />
                </div>
            </Dialog>
            <Dialog visible={deleteGigDialog} style={{ width: '450px' }} header="Opravdu?" modal footer={deleteGigDialogFooter} onHide={hideDeleteGigDialog}>
                <div className={styles.confirmationContent}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {gig && <span>Určitě chceš smazat koncert <b>{gig.name}</b>?</span>}
                </div>
            </Dialog>
            <Toast ref={toast} />
        </div>
    )
}

export default Gigs