import React from 'react';
import axios from 'axios';

const { createContext, useContext } = React;

export const MailProvider = (props) => {
    const value = {
        send: props.send || send,
    };

    return (
        <MailProvider.Provider value={value}>
            {props.children}
        </MailProvider.Provider>
    );
};

const send = (email, subject, text) => {
    const body = {
        email: email,
        subject: subject,
        text: text
    }
    return axios.post(process.env.REACT_APP_API_URL + "/email/contact", body)
        .then(result => {
            return result.data
        });
};

const MailContext = createContext({ send: send });

export const useMail = () => {
    return useContext(MailContext);
};
