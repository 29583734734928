import { React } from 'react'
import styles from './overlay.module.scss'

const overlay = (props) => {
    var classes = [styles.overlay]

    if (props.visible) {
        classes.push(styles.visible)
    }

    return (
        <div className={classes.join(" ")} onClick={props.click}>
        </div>
    )
}

export default overlay;