import React from 'react'
import styles from './flashyButton.module.scss'

const flashyButton = props => {
    return (
        <div className={styles.wrapper} onClick={props.onClick}>
            <div className={styles.btn}>
                <span>{props.label}</span>
            </div>
        </div>
    )
}

export default flashyButton