import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { Auth } from '../../../service/auth/auth'

const AuthorizedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const account = Auth.currentAccountValue
        return (account && account.token.isRole(rest.role) ? <Component {...props} />
            : <Redirect to={{ pathname: '/error/403' }} />
        )
    }
    } />
)

export default AuthorizedRoute