import React from 'react';
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'

import styles from './input.module.scss'

const input = (props) => {
    let inputElement = null;
    const _props = { ...props }
    const elementType = _props.elementType
    delete _props.elementType
    delete _props.valid

    switch (elementType) {

        case ('checkbox'):
            inputElement = <Checkbox {..._props} checked={props.value} />
            break
        case ('dropdown'):
            inputElement = <Dropdown {..._props} className={props.valid ? "" : "p-invalid"} />
            break
        case ('date'):
            inputElement = <Calendar {..._props} dateFormat="dd.mm.yy" className={props.valid ? "" : "p-invalid"} showIcon />
            break
        case ('time'):
            inputElement = <Calendar {..._props} className={props.valid ? "" : "p-invalid"} timeOnly hourFormat="24" showIcon />
            break
        case ('textArea'):
            inputElement = <InputTextarea {..._props} className={props.valid ? "" : "p-invalid"} />
            break
        case ('input'):
        default:
            inputElement = <InputText {..._props} className={props.valid ? "" : "p-invalid"} />
            break
    }

    var errorHintClass = ["p-invalid", "p-d-block", styles.errorHint];
    if (props.valid) {
        errorHintClass.push(styles.invisible);
    }

    return (
        <div className="p-field">
            <div className="p-float-label">
                {inputElement}
                <label>{props.label}</label>
            </div>
            <small
                id={props.id + "-help"}
                className={errorHintClass.join(" ")}>
                {props.error}
            </small>
        </div>
    );
};

export default input;