import React from 'react';
import styles from './social-icon.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const socialIcon = (props) => {
    let classes = props.classes + " " + styles.social;
    return (
        <div>
            <a className={classes} rel="noreferrer" href={props.url} target="_blank">
                <FontAwesomeIcon icon={props.icon} />
            </a>
        </div>
    )
}

export default socialIcon;