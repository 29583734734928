import { React } from 'react';
import Logo from '../menuLogo/menuLogo';
import Hamburger from '../UI/buttons/hamburger/hamburger';
import styles from './navbar.module.scss';

const Navbar = (props) => {
    return (
        <nav className={styles.mainNav + " " + props.className + " p-d-flex p-p-3 p-grid p-nogutter"}>
            <div className="p-sm-3 p-col-2">
                <Logo />
            </div>
            <div className="p-col"></div>
            <div className="p-sm-3 p-col-2">
                <Hamburger onClick={props.onMenuClick} size='30px' />
            </div>

        </nav >)
}

const Spacer = () => {
    return (<Navbar className={styles.spacer}></Navbar>);
}

export default Navbar;

export { Spacer };