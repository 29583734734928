import React from 'react'
import styles from './footer.module.scss';
import space from '../../assets/images/space.png';

import SocialIcon from './social-icon/social-icon';
import { faFacebookF, faInstagram, faItunesNote, faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons'

const footer = (props) => {
    return (
        <footer
            style={{ backgroundImage: `url(${space})` }}
            className={styles.footer + " p-py-5"}>
            <div className="p-d-flex p-px-5">
                <SocialIcon icon={faFacebookF} classes={styles.facebook} url="https://www.facebook.com/Easy-Steps-499231733442400/" />
                <SocialIcon icon={faInstagram} classes={styles.instagram} url="https://instagram.com/easystepsofficial/" />
                <SocialIcon icon={faItunesNote} classes={styles.itunes} url="https://itunes.apple.com/cz/artist/easy-steps/id1210816843?l=cs" />
                <SocialIcon icon={faSpotify} classes={styles.spotify} url="https://play.spotify.com/artist/4wnAJJteZOr4tntwidVe60" />
                <SocialIcon icon={faYoutube} classes={styles.youtube} url="https://www.youtube.com/channel/UCdHIEuzfqkSIyZ4NKcJ_LrQ" />

            </div>
            <div
                className={styles.copyright + " p-text-right p-text-light p-px-3"}>
                Copyright &copy; Easy Steps {new Date().getFullYear()}
            </div>
        </footer >
    );
}

export default footer;