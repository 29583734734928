import React, { useState, useEffect, useCallback } from 'react'
import styles from './gallery.module.scss'

import useGalleryApi from '../../service/api/galleryApi';

import Photos from 'react-photo-gallery';
//import { ProgressSpinner } from 'primereact/progressspinner';
import InfiniteProgress from '../UI/infiniteProgress/infiniteProgress'
import { Message } from 'primereact/message';

import TitleImage from '../titleImage/titleImage';

import image from '../../assets/images/gallery.png';

import Lightbox from '../lightbox/lightbox';

const Gallery = (props) => {
    const api = useGalleryApi();

    const [gallery, setGallery] = useState([])
    const [galleryLoading, setGalleryLoading] = useState(true)
    const [galleryError, setGalleryError] = useState(false)

    const [lightbox, setLightbox] = useState([])
    const [currentImage, setCurrentImage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    useEffect(() => {
        api.getAll().then(result => {
            setGalleryLoading(false)
            setGalleryError(false)

            const params = result.map(toGalleryParams)

            setGallery(params)

            const light = params.map(x => (x.src))
            setLightbox(light)
        }).catch(error => {
            console.error(error.message);
            setGalleryLoading(false)
            setGalleryError(true)
            setGallery([])
        });
    }, [api]);

    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
            <div className={styles.imgWrapper} style={{ height: photo.height, width: photo.width }}>
                <img
                    alt={photo.alt}
                    src={photo.src}
                    key={key}
                    left={left}
                    top={top}
                    style={{ height: '100%', width: '100%' }}
                    onClick={() => openLightbox(index)}
                />
            </div>
        ),
        []
    );

    const openLightbox = index => {
        setCurrentImage(index);
        setLightboxIsOpen(true);
    };

    var content;
    if (galleryLoading) {
        content = (<InfiniteProgress size="80px" />)
    } else if (galleryError) {
        content = (<Message className={styles.error} severity="error" text="Galerii se nepodařilo načíst" />)
    } else {
        if (gallery.length) {
            content = (<div>
                <Photos
                    photos={gallery}
                    targetRowHeight={calculateGalleryHeight}
                    onClick={openLightbox}
                    renderImage={imageRenderer} />
                {lightboxIsOpen ? (
                    <Lightbox images={lightbox} startIndex={currentImage} onClose={() => setLightboxIsOpen(false)} showTitle={false} allowRotate={false} allowZoom={false} allowReset={false} />) :
                    null
                }
            </div>)
        } else {
            content = (
                <div className={styles.emptyMessage}>V galerii nejsou žádné obrázky</div>
            );
        }
    }

    return (
        <div className={styles.wrapper}>
            <TitleImage image={image} alt="Galerie" height="80" glitch="false" spotlight="true">
                {getSvg(0, "#000000A0")}
            </TitleImage>
            {content}
        </div>
    )
}


const calculateGalleryHeight = containerWidth => {
    if (containerWidth >= 992) {
        return 350
    } else if (containerWidth >= 768) {
        return 300
    } else {
        return 200
    }
}

const toGalleryParams = dto => {
    let largest = 0
    let largestImage = null

    const srcSet = []

    for (let size in dto.urls) {
        if (parseInt(size) > parseInt(largest)) {
            largest = size
            largestImage = dto.urls[size]
        }

        srcSet.push(dto.urls[size] + " " + size + "w")
    }

    const sizes = ["(min-width: 480px) 50%,(min-width: 1024px) 33.3%,100%"]

    return { src: largestImage, srcSet: srcSet, width: dto.width, height: dto.height, sizes: sizes, alt: dto.alt }
}

const getSvg = (counter, shadowColor) => {
    const filterName = "shadow" + counter
    const style = { filter: "url(#" + filterName + ")" }

    return (<svg className={styles.text + " " + styles["text" + counter]} alt="Galerie" data-name="Galerie" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 980.95 290.31">
        <filter id={filterName}>
            <feDropShadow dx="7" dy="7" stdDeviation="3"
                floodColor={shadowColor} />
        </filter>
        <title>galerie</title>
        <g style={style} >
            <polygon points="471.98 290 593.76 290 593.76 247.31 516.3 247.31 516.3 163.65 561.86 163.65 578.43 142.09 561.86 120.96 516.3 120.96 516.3 42.25 593.76 42.25 593.76 0 471.98 0 471.98 290" />
            <path d="M822.4,682.83,774.59,566.15c22.12-10.42,33-32.34,33-64.42V464.21c.06-46.2-22.75-71.38-68.81-71.38H671.58v290h44.08V573.1H731.5l43.71,109.73ZM715.77,432.94h25c14.44,0,22.67,6.61,22.67,27.22v42.06l.14-.08c0,20.63-8.26,27.22-22.67,27.22H715.77Z" transform="translate(-59.41 -392.52)" />
            <path d="M181,483.86l22.49-16V463a79.52,79.52,0,0,0-5.08-28.93c-8.45-26.89-30.57-41.31-66.82-41.31h-.3c-41,.12-71.91,25.21-71.91,70.23l1.68,149.92c0,44.34,24,69.84,71,69.84s68.66-25.5,68.66-69.84V577.79h0V535.54H128v42.25h30.9V615.3c0,20-12.16,27.46-27.47,27.46s-23.93-7.44-23.93-27.46L105,459.88c0-19.75,11.48-26.89,26.51-27.07,15,.18,26.5,7.32,26.5,27.07v8Z" transform="translate(-59.41 -392.52)" />
            <path d="M290.87,392.78c-46.32,0-68.88,23.35-68.88,68.48V682.78h43V559.06l26.17,15.3,26.57-15.3V682.78h45.11V461.26C363,416.13,336.78,392.78,290.87,392.78Zm26.89,130.51H265.48V465.67c0-18.53,9.65-26.57,23-27.38h6.86c13.28,1.21,22.56,9.26,22.56,27.38v57.59Z" transform="translate(-59.41 -392.52)" />
            <polygon points="859.16 290.26 980.95 290.26 980.95 247.57 903.48 247.57 903.48 163.91 949.05 163.91 965.62 142.36 949.05 121.22 903.48 121.22 903.48 42.51 980.95 42.51 980.95 0.26 859.16 0.26 859.16 290.26" />
            <polygon points="325.73 290.26 445.88 290.26 460.38 268.32 445.88 245.94 370.58 245.94 370.58 0.26 325.73 0.26 325.73 290.26" />
            <polygon points="782.45 1.38 782.45 274.6 803.84 289.14 825.66 274.6 825.66 1.38 782.45 1.38" />
        </g>
    </svg>);
}

export default Gallery;