import React from 'react';
import styles from './loadingScreen.module.scss';
import InfiniteProgress from '../UI/infiniteProgress/infiniteProgress';

const LoadingScreen = props => {
    return (<div className={styles.wrapper}>
        <InfiniteProgress size="80px" />
        <div>Děláme na tom!</div>
    </div>)
}

export default LoadingScreen;