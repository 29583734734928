import { callApiGet, callApiPost } from './api'
import { Auth } from '../auth/auth'
import setAuthHeader from '../auth/authHeader'
import axios from 'axios'

import createAuthRefreshInterceptor from '../auth/refresh/index'

const refreshAuthLogic = async failedRequest => {
    await Auth.refresh()
    setAuthHeader(failedRequest.response.config)
}

createAuthRefreshInterceptor(axios, refreshAuthLogic)

axios.interceptors.request.use(
    request => {
        setAuthHeader(request)
        return request
    }, error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => response,
    async error => {
        if (error && error.response && error.response.status) {
            if (error.response.status === 401 || error.response.status === 403) {
                await Auth.logout()
            }
        }
        throw error
    }
)

const refreshAccessToken = () => {
    return callApiGet("/user/refresh", { skipAuthRefresh: true })
}

const login = (email, password) => {
    return callApiPost("/user/login", { email: email, password: password }, { skipAuthRefresh: true })
}

const fbLogin = token => {
    return callApiPost("/user/fblogin", { token: token }, { skipAuthRefresh: true })
}

const logout = () => {
    return callApiGet("/user/logout")
}

const me = () => {
    return callApiGet("/user")
}

const authApi = {
    login: login,
    fbLogin: fbLogin,
    logout: logout,
    me: me,
    refresh: refreshAccessToken
}

export default authApi