import React, { useRef, useState } from 'react';

import Form from '../form/form';

import { Spacer } from '../../components/navbar/navbar'
import ContactPanel from './contact-panel/contact-panel';

import { useMail } from '../../service/mail/mail';

import { Button } from 'primereact/button'

import InfiniteProgress from '../../components/UI/infiniteProgress/infiniteProgress'
import { Messages } from 'primereact/messages'


import styles from './contact.module.scss';

const Contact = () => {
    const mail = useMail()
    const formRef = useRef()
    const msgs = useRef(null)
    const [submitting, setSubmitting] = useState(false)

    const formConfig = {
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Tvůj email'
            },
            validation: {
                required: {
                    error: 'Nevíme kam Ti odpovědět!'
                },
                email: {
                    error: 'Takhle emailová adresa nevypadá!'
                }
            }
        },
        subject: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Předmět zprávy'
            },
            validation: {
                required: {
                    error: 'Bez předmětu by to nešlo!'
                }
            }
        },
        text: {
            elementType: 'textArea',
            elementConfig: {
                rows: 5,
                autoResize: true,
                label: 'Tvá zpráva'
            },
            validation: {
                required: {
                    error: 'Bez zprávy by to nešlo!'
                }
            }
        }
    }

    const submit = formData => {
        setSubmitting(true)
        return mail.send(formData['email'].value, formData['subject'].value, formData['text'].value)
            .finally(() => { setSubmitting(false) })
    }

    const onSuccess = msg => {
        msgs.current.show({ severity: 'success', summary: msg, sticky: true })
    }

    const onFailure = msg => {
        msgs.current.show({ severity: 'error', summary: msg, sticky: true })
    }

    const handleSubmit = () => {
        formRef.current.triggerSubmit()
    }

    const buttonClassNames = ["p-button-primary", styles.submit]
    if (submitting) {
        buttonClassNames.push(styles.hideLabel)
    }

    return (
        <div>
            <div className={styles.contentWrapper}>
                <Spacer></Spacer>
                <h1>Napiš nám</h1>
                <div className={styles.formWrapper}>
                    <div className={styles.formFieldsWrapper}>
                        <Form
                            ref={formRef}
                            config={formConfig}
                            onSubmit={formData => submit(formData)}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            submitting={submitting}
                        />
                    </div>
                    <div className={styles.controlsWrapper}>
                        <Messages ref={msgs} />
                        <Button label="Odeslat" className={buttonClassNames.join(" ")} onClick={handleSubmit} disabled={submitting}>
                            <InfiniteProgress size="2rem" className={!submitting && styles.hidden} />
                        </Button>
                    </div>
                </div>
                <ContactPanel />
            </div>
        </div >
    )
}

export default Contact;