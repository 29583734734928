import { Button } from 'primereact/button'
import styles from './confirmButton.module.scss'
import React from 'react'
import InfiniteProgress from '../../../../UI/infiniteProgress/infiniteProgress'

const ConfirmButton = props => {
    const classes = [styles.confirmButton]
    classes.push(props.className ? props.className : "p-button-success")
    if (props.submitting) {
        classes.push("p-disabled")
    }
    return (
        <Button label={props.label ? props.label : "Uložit"} icon="pi pi-check" className={classes.join(" ")} onClick={props.onClick} >
            <InfiniteProgress size="2rem" className={!props.submitting && styles.hidden} />
        </Button>
    )
}

export default ConfirmButton