import React, { useState } from 'react';

// import { ProgressSpinner } from 'primereact/progressspinner';

import InfiniteProgress from '../../UI/infiniteProgress/infiniteProgress';
import { Accordion } from 'primereact/accordion';
import { Message } from 'primereact/message';
import { Paginator } from 'primereact/paginator';

import IGig from '../../../model/IGig';
import Gig from '../gig/gig';

import styles from './section.module.scss';

interface SectionProps {
    gigs: IGig[],
    loading: boolean,
    error: boolean,
    emptyMsg: string
}

function Section(props: SectionProps) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [first, setFirst] = useState(0)
    var rows = 20

    var gigs: JSX.Element[] = [];

    const onPageChange = (event: any) => {
        setFirst(event.first)
    }

    if (props.gigs) {
        gigs = props.gigs.map(gig => {
            return Gig(gig)
        })
    }

    var paginator
    var content
    if (props.loading) {
        content = (<InfiniteProgress size="80px" />);
    } else if (props.error) {
        content = (<Message severity="error" text="Koncerty se nepodařilo načíst" />);
    } else {
        if (gigs.length) {
            if (gigs.length > rows) {
                paginator = <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={gigs.length}
                    onPageChange={onPageChange} ></Paginator >
            }
            content = (
                <div>
                    {paginator}
                    <Accordion
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                        expandIcon=""
                        collapseIcon=""
                    >
                        {gigs.slice(first, first + rows)}
                    </Accordion>
                    {paginator}
                </div>
            );
        } else {
            content = (
                <div className={styles.emptyMessage}>{props.emptyMsg}</div>
            );
        }
    }

    return (
        <div className={styles.wrapper}>
            {content}
        </div>
    )
}

export default Section;