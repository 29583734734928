import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useState, useEffect, useRef } from 'react'
import Form from '../../../containers/form/form'
import useLocationsApi from '../../../service/api/locationApi'
import DataTable from '../../dataTable/dataTable'
import Map from '../../map/map'
import ConfirmButton from '../components/buttons/confirmButton/confirmButton'
import CreateButton from '../components/buttons/createButton/createButton'
import DeclineButton from '../components/buttons/declineButton/declineButton'

import styles from './locations.module.scss'

const Locations = props => {
    const locationsApi = useLocationsApi()

    const [loading, setLoading] = useState(false)
    const [locations, setLocations] = useState(null)
    const [lazyParams, setLazyParams] = useState({ sortField: 'name', sortOrder: 1 })
    const [first, setFirst] = useState(0)
    const [location, setLocation] = useState(null)
    const [locationDialog, setLocationDialog] = useState(false)
    const [deleteLocationDialog, setDeleteLocationDialog] = useState(false)
    const [version, setVersion] = useState(0)
    const [mapShown, setMapShown] = useState(false)

    const formRef = useRef(null)
    const toast = useRef(null)
    const [submitting, setSubmitting] = useState(false)

    const formConfig = {
        id: {
            elementType: 'input',
            elementConfig: {
                type: 'hidden'
            }
        },
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Název'
            }, validation: {
                required: {
                    error: 'Na bezejmenných místech nehrajeme'
                }
            }
        },
        city: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Město'
            }, validation: {
                required: {
                    error: 'Jaké město poctíme svou přítomností?'
                }
            }
        },
        country: {
            elementType: 'input',
            elementConfig: {
                type: 'hidden'
            }
        },
        address: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Adresa'
            }
        },
        url: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'URL'
            }
        },
        map: {
            elementType: 'checkbox',
            elementConfig: {
                label: 'Mapa'
            }
        },
        latitude: {
            elementType: 'input',
            elementConfig: {
                type: 'hidden',
                datatype: 'number'
            }
        },
        longitude: {
            elementType: 'input',
            elementConfig: {
                type: 'hidden',
                datatype: 'number'
            }
        },
        zoom: {
            elementType: 'input',
            elementConfig: {
                type: 'hidden',
                datatype: 'number'
            }
        }
    }

    const emptyLocation = {
        id: null,
        name: '',
        city: '',
        country: 'CZE',
        address: null,
        url: null,
        map: false,
        latitude: 49.830761,
        longitude: 18.280226,
        zoom: 14
    }

    const onRowEditInit = event => {
        const _location = {}

        Object.keys(formConfig).forEach(key => {
            _location[key] = { value: event.data[key] ? event.data[key] : '' }

            if (["latitude", "longitude", "zoom"].includes(key) && !event.data[key]) {
                _location[key] = { value: emptyLocation[key] }
            }
        })

        _location.map = { value: ((event.data.latitude != null) && (event.data.longitude != null) && (event.data.zoom != null)) }

        setMapShown(_location.map.value)

        setLocation(_location)
        setLocationDialog(true)
    }

    const hideDialog = () => {
        setLocation(null)
        setLocationDialog(false)
    }

    const saveLocation = () => {
        setSubmitting(true)
        formRef.current.triggerSubmit()
            .then(() => {
                setLocationDialog(false)
                setLocation(null)
            }).catch(error => {
                if (!error) {
                    return
                }
            }).finally(() => {
                setSubmitting(false)
            })
    }

    const submit = formData => {
        const data = {}

        Object.keys(formData).forEach(input => {
            data[input] = normalizeInputValue(input, formData[input].value)
        })

        if (!data.map) {
            data.latitude = null
            data.longitude = null
            data.zoom = null
        }

        delete data.map

        if (data.id) {
            return locationsApi.update(data).then(result => {
                setVersion(version + 1)
                return result
            }).then(data => {
                showSuccess('Koncertní místo upraveno')
                return data
            })
        } else {
            return locationsApi.create(data).then(result => {
                setVersion(version + 1)
                return result
            }).then(data => {
                showSuccess('Koncertní místo vytvořeno')
                return data
            })
        }
    }

    const normalizeInputValue = (key, value) => {
        if (typeof value === 'string') {
            if (/\S/.test(value)) {
                return value.trim()
            } else {
                return null
            }
        }

        return value
    }

    const locationDialogFooter = (
        <React.Fragment>
            <DeclineButton onClick={hideDialog} submitting={submitting} />
            <ConfirmButton onClick={saveLocation} submitting={submitting} />
        </React.Fragment>
    )

    const columns = [
        { field: 'id', label: 'ID', sortable: true },
        { field: 'name', label: 'Název', sortable: true, filter: true },
        { field: 'city', label: 'Město', sortable: true, filter: true },
        { field: 'address', label: 'Adresa' },
        { field: 'url', label: 'URL' },
        { field: 'latitude', label: 'Zem.Šířka' },
        { field: 'longitude', label: 'Zem.Délka' },
        { field: 'zoom', label: 'Zoom' }
    ]

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event }
        setLazyParams(_lazyParams)
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event }
        setFirst(0)
        setLazyParams(_lazyParams)
    }

    const onPage = (event) => {
        setFirst(event.first)
    }

    const showSuccess = msg => {
        toast.current.show({ severity: 'success', summary: 'Cajk', detail: msg, life: 3000 })
    }

    const showFailure = msg => {
        toast.current.show({ severity: 'error', summary: 'Chyba', detail: msg, life: 3000 })
    }

    const openNew = () => {
        onRowEditInit({ data: emptyLocation })
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <CreateButton onClick={openNew} />
            </React.Fragment>
        )
    }

    const deleteLocationConfirm = location => {
        setLocation(location)
        setDeleteLocationDialog(true)
    }

    const hideDeleteLocationDialog = () => {
        setDeleteLocationDialog(false)
        setLocation(null)
    }

    const deleteLocation = () => {
        setSubmitting(true)

        locationsApi.delete(location.id).then(result => {
            showSuccess('Koncertní místo smazáno')
        }).catch(error => {
            showFailure(error.message)
        }).finally(() => {
            setSubmitting(false)
            setLocation(null)
            setVersion(version + 1)
            setDeleteLocationDialog(false)
        })
    }

    const deleteLocationDialogFooter = (
        <React.Fragment>
            <DeclineButton label="Ne" className="p-button-warning" onClick={hideDeleteLocationDialog} submitting={submitting} />
            <ConfirmButton label="Ano" className="p-button-danger" onClick={deleteLocation} submitting={submitting} />
        </React.Fragment>
    )

    useEffect(() => {
        setLoading(true)

        locationsApi.getAll(lazyParams).then(data => {
            setLocations(data)
            setLoading(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams, locationsApi, version])

    const mapCenterChanged = center => {
        formRef.current.setValue("latitude", center.lat.toFixed(6))
        formRef.current.setValue("longitude", center.lng.toFixed(6))
    }

    const mapZoomChanged = zoom => {
        formRef.current.setValue("zoom", zoom)
    }

    const onFormChange = (event, elementKey) => {
        if (elementKey === "map") {
            setMapShown(event.target.checked)
        }
    }

    const mapClasses = [styles.mapWrapper]
    if (!mapShown) {
        mapClasses.push(styles.hidden)
    }

    return (
        <div className={styles.content}>
            <div className="p-card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                <DataTable data={locations}
                    lazyParams={lazyParams}
                    first={first}
                    onSort={onSort}
                    onFilter={onFilter}
                    loading={loading}
                    onPage={onPage}
                    columns={columns}
                    editMode="dialog"
                    dataKey="id"
                    onRowEditInit={onRowEditInit}
                    onDeleteRow={deleteLocationConfirm}
                />
            </div>
            <Dialog visible={locationDialog} style={{ width: '450px' }} header="Koncertní místo" modal className="p-fluid" footer={locationDialogFooter} onHide={hideDialog}>
                <div className={styles.locationDialogContent}>
                    <Form
                        ref={formRef}
                        config={formConfig}
                        onSubmit={formData => submit(formData)}
                        submitting={submitting}
                        formData={location}
                        onFailure={showFailure}
                        onChange={onFormChange}
                    />
                    <div className={mapClasses.join(" ")}>
                        <Map
                            search={true}
                            lat={location && location.latitude ? location.latitude.value : 0}
                            lng={location && location.longitude ? location.longitude.value : 0}
                            zoom={location && location.zoom ? location.zoom.value : 0}
                            onCenterChanged={mapCenterChanged}
                            onZoomChanged={mapZoomChanged}
                        />
                        <div className={styles.markerWrapper + " p-d-flex p-jc-center p-ai-center"}>
                            <svg>
                                <circle cx="50" cy="50" r="40" fill="transparent" stroke="red" strokeWidth="2px" />
                                <line x1="0" y1="50" x2="102" y2="50" stroke="red" strokeWidth="1px" />
                                <line x1="50" y1="0" x2="50" y2="102" stroke="red" strokeWidth="1px" />
                            </svg>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog visible={deleteLocationDialog} style={{ width: '450px' }} header="Opravdu?" modal footer={deleteLocationDialogFooter} onHide={hideDeleteLocationDialog}>
                <div className={styles.confirmationContent}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {location && <span>Určitě chceš smazat koncertní místo <b>{location.name}</b>?</span>}
                </div>
            </Dialog>
            <Toast ref={toast} />
        </div>
    )
}

export default Locations