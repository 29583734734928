import { Button } from 'primereact/button'
import React from 'react'

const DeclineButton = props => {
    const classes = []
    classes.push(props.className ? props.className : "p-button-danger")
    if (props.submitting) {
        classes.push("p-disabled")
    }
    return (
        <Button label={props.label ? props.label : "Zrušit"} icon="pi pi-times" className={classes.join(" ")} onClick={props.onClick} />
    )
}

export default DeclineButton