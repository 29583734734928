import React from 'react';

import { AccordionTab } from 'primereact/accordion';

import IGig from '../../../model/IGig';
import ILocation from '../../../model/ILocation';

import Lineup from './lineup/lineup';
import GigButton from './gigButton/gigButton';
import Map from '../../map/map';

import styles from './gig.module.scss';

const Gig = (props: IGig) => {

    const fbButton = props.facebook_event ? <GigButton type="fb" url={props.facebook_event} /> : null;
    const webButton = props.web ? <GigButton type="web" url={props.web} /> : null;

    const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(props.date).toLocaleDateString('cs-CZ', options);

    const header = getHeader(props, date.toUpperCase());

    const dateElement = gigSection("Datum", [(<div>{date}</div>)]);
    const timeElement = getTime(props.time);
    const locationElement = getLocation(props.location);

    const separator = (props.admission || props.stage || props.lineup) ? <div className={styles.separator}></div> : null;

    const admissionElement = props.admission ? gigSection("Vstupné", [(<div>{props.admission}</div>)]) : null;
    const stageElement = props.stage ? gigSection("Stage", [(<div>{props.stage}</div>)]) : null;
    const lineupElement = props.lineup ? gigSection("Program", [(<Lineup lineupJSON={props.lineup} />)]) : null;
    const mapElement = (props.location.latitude && props.location.longitude && props.location.zoom) ? getMap(props.location) : null;

    return (
        <AccordionTab header={header} headerClassName={styles.header}>
            {fbButton}
            {webButton}
            <div className={styles.detail + " p-grid"}>
                <div className={styles.protocol + " p-col-12 p-md-6"}>
                    {dateElement}
                    {timeElement}
                    {locationElement}

                    {separator}

                    {admissionElement}
                    {stageElement}
                    {lineupElement}
                </div>
                <div className={styles.map + " p-col-12 p-md-6"}>
                    {mapElement}
                </div>
            </div>
        </AccordionTab>
    )
}

const getMap = (location: ILocation) => {
    return (
        <div className={styles.mapWrapper}>
            <div className={styles.mapSpacer}>
                <Map
                    lat={location.latitude}
                    lng={location.longitude}
                    zoom={location.zoom}
                    marker={true} />
            </div>
        </div>
    );
}

const getHeader = (gig: IGig, date: string): JSX.Element => {
    return (
        <React.Fragment>
            <div className="p-grid">
                <div className={styles.info + " p-d-flex p-flex-column p-col-12 p-md-3 p-ai-start"}>
                    <div>{date}</div>
                    <div>{gig.location.name}</div>
                    <div>{gig.location.city}</div>
                </div>
                <div className="name p-col-12 p-md-9 p-as-center">
                    {gig.name}
                </div>
            </div>
            <div className={styles.marker}></div>
        </React.Fragment>
    )
}

const getLocation = (location: ILocation) => {
    const locationElements = [(<div>{location.name}</div>)]
    if (location.address) {
        locationElements.push((<div>{location.address}</div>));
    }
    return gigSection("Místo", locationElements);
}

const getTime = (time: string | undefined) => {
    if (time) {
        var t = time.split(/[- :]/);
        
        return gigSection("Čas", [(<div>{
            t[0] + ":" + t[1]
        }</div>)]);
    }

    return null;
}


const gigSection = (header: string, elements: JSX.Element[]) => {
    return (
        <div className="p-grid">
            <div className="p-col-3 p-md-3">{header}</div>
            <div className="p-col-9 p-md-9 p-d-flex p-flex-column">
                {elements}
            </div>
        </div>
    );
}

export default Gig;