import React from 'react';
import styles from './bannerText.module.scss';

const BannerText = props => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {props.children}
            </div>
        </div>
    );
}

export default BannerText;