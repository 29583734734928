import { Auth } from './auth'

const authHeader = () => {
    // return authorization header with jwt token
    const account = Auth.currentAccountValue
    if (account) {
        return `Bearer ${account.token.token}`
    } else {
        return null
    }
}

const setAuthHeader = config => {
    const authorization = authHeader()
    if (authorization) {
        config.headers['Authorization'] = authorization
    }
}

export default setAuthHeader