import { BehaviorSubject } from 'rxjs'
import authApi from '../api/authApi'
import JWT from '../../utils/jwt'

const currentAccountSubject = new BehaviorSubject(null)

export const ROLES = {
    SU: { code: "SU", label: "Super User" },
    GIGS_ADMIN: { code: "GIGS_ADMIN", label: "Gigs Admin" },
    GALLERY_ADMIN: { code: "GALLERY_ADMIN", label: "Gallery Admin" },
    BLOG_ADMIN: { code: "BLOG_ADMIN", label: "Blog Admin" },
}

const isRole = (role) => {
    const account = currentAccountSubject.value
    if (account) {
        return account.token.isRole(role) || account.token.isSU()
    }
    return false
}

const login = async () => {
    // login with facebook then authenticate with the API to get a JWT auth token
    const { authResponse } = await new Promise(resolve => {
        window.FB.login(authResponse => {
            resolve(authResponse)
        }, { scope: 'email' })
    })

    if (!authResponse) return

    await fbLogin(authResponse.accessToken)
}

const fbLogin = async fbToken => {
    const response = await authApi.fbLogin(fbToken)
    return await processAuthResponse(response)
}

const refresh = async () => {
    const response = await authApi.refresh()
    await processAuthResponse(response)
}

const processAuthResponse = async response => {
    const account = {
        token: new JWT(response.token),
        user: response.user
    }
    currentAccountSubject.next(account)
    return account
}

const logout = async () => {
    // remove user from local storage to log user out
    currentAccountSubject.next(null)

    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    await new Promise(resolve => {
        window.FB.getLoginStatus(({ authResponse }) => {
            if (authResponse) {
                window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout(resolve()))
            } else {
                resolve()
            }
        })
    })
}

export const Auth = {
    login,
    fbLogin,
    logout,
    refresh,
    currentAccount: currentAccountSubject.asObservable(),
    get currentAccountValue() {
        return currentAccountSubject.value
    },

    isRole: isRole,
}