import { React } from 'react';
import Video from '../video/video';

import mp4 from '../../assets/video/Glitch/Glitch.mp4';
import ogv from '../../assets/video/Glitch/Glitch.ogv';
import webm from '../../assets/video/Glitch/Glitch.webm';

import poster from '../../assets/video/Glitch/Glitch.jpg';

import Banner from '../banner/banner';
import BannerText from '../bannerText/bannerText';

import concert from '../../assets/images/concert2.jpg';
import band from '../../assets/images/band.jpg';
import gallery from '../../assets/images/gallery.png';

import styles from './index.module.scss';

const Index = () => {
    return (
        <div>
            <Video poster={poster} sources={[
                { src: mp4, type: "video/mp4" },
                { src: ogv, type: "video/ogv" },
                { src: webm, type: "video/webm" }
            ]} />

            <BannerText>
                <div>
                    <h3>Vítejte u Easy Steps!</h3>
                    <p>pop-rockové kapely z Ostravy</p>
                </div>
            </BannerText>

            <Banner background={band} title="Kapela" buttonLabel="Poznej nás o něco blíže" link="/kapela" />

            <BannerText>
                <div className={styles.videoWrapper}>
                    <iframe title="Minus Plus"
                        src="https://www.youtube.com/embed/lXqqbKsOYlM" allowFullScreen>
                    </iframe>
                </div>
            </BannerText>

            <Banner background={concert} title="Koncerty" buttonLabel="Prohlédni si, kde nás můžeš vidět" link="/koncerty" />

            <BannerText>
                <div>
                    <h1>TAKE IT EASY!</h1>
                </div>
            </BannerText>

            <Banner background={gallery} title="Galerie" buttonLabel="Výběr toho povedenějšího" link="/galerie" />

        </div>);
}

export default Index;