import React from 'react';
import PropTypes from 'prop-types';
import styles from './gigButton.module.scss';

import { Button } from 'primereact/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

const GigButton = (props) => {

    const clazz = [styles.button];
    var label;
    var icon;

    switch (props.type) {
        case 'web':
            clazz.push(styles.web);
            label = 'Web události';
            icon = faGlobeEurope;
            break;
        case 'fb':
            clazz.push(styles.fb);
            label = 'Událost na Facebooku';
            icon = faFacebookF;
            break;
        default:
            throw new Error("Expected fb or web button type");

    }

    return (<a target="_blank" rel="noreferrer" className={clazz.join(" ")} href={props.url}>
        <Button className="p-d-flex p-jc-center"><FontAwesomeIcon icon={icon} />{label}</Button>
    </a>);
}

GigButton.propTypes = {
    type: PropTypes.oneOf(['fb', 'web']).isRequired,
    url: PropTypes.string.isRequired
};

export default GigButton;