import React from 'react';

import styles from './sidebar.module.scss';

import Link from './link/link';
import Times from '../UI/buttons/times/times';
import { Logo } from '../graphics/graphics';

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible) {
            document.addEventListener("keydown", this.handleKeyPressed, false);
        } else {
            document.removeEventListener("keydown", this.handleKeyPressed, false);
        }
    }

    handleKeyPressed(event) {
        // ESC
        if (event.keyCode === 27) {
            this.props.onMenuClose();
        }
    }

    render() {
        var classes = [styles.sidebar]

        if (this.props.visible) {
            classes.push(styles.visible)
        }

        return (
            <div className={classes.join(" ")}>
                <div className={styles.close}>
                    <Times onClick={this.props.onMenuClose} size='2.5rem' />
                </div>
                <Link link="/" onClick={this.props.onMenuClose}><Logo styles={styles.logo} /></Link>
                <Link link="/kapela" onClick={this.props.onMenuClose}>kapela</Link>
                <Link link="/koncerty" onClick={this.props.onMenuClose}>koncerty</Link>
                <Link link="/galerie" onClick={this.props.onMenuClose}>galerie</Link>
                <Link link="/kontakt" onClick={this.props.onMenuClose}>kontakt</Link>

                <iframe title="spotify" src="https://open.spotify.com/embed/artist/4wnAJJteZOr4tntwidVe60" height="200px" frameBorder="0"
                    allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
        )
    }
}

export default Sidebar