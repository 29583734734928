import React from 'react';
import styles from './infiniteProgress.module.scss';

import UnitUtils from '../../../utils/UnitUtils'

const InfiniteProgress = (props) => {
    var size = 40;
    var unit = 'px';
    if (props.size) {
        [size, unit] = UnitUtils.parseSize(props.size);
    }

    const barSize = (size / 15) + unit;

    return (
        <div className={props.className + ` loader-container`} style={{ width: size + unit, height: (size / 4) + unit }}>
            <div className={styles.rectangle1} style={{ width: barSize }}></div>
            <div className={styles.rectangle2} style={{ width: barSize }}></div>
            <div className={styles.rectangle3} style={{ width: barSize }}></div>
            <div className={styles.rectangle4} style={{ width: barSize }}></div>
            <div className={styles.rectangle5} style={{ width: barSize }}></div>
            <div className={styles.rectangle6} style={{ width: barSize }}></div>
            <div className={styles.rectangle5} style={{ width: barSize }}></div>
            <div className={styles.rectangle4} style={{ width: barSize }}></div>
            <div className={styles.rectangle3} style={{ width: barSize }}></div>
            <div className={styles.rectangle2} style={{ width: barSize }}></div>
            <div className={styles.rectangle1} style={{ width: barSize }}></div>
        </div>)
}

export default InfiniteProgress;