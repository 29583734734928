import React from 'react'

import styles from './contact-panel.module.scss';

import { Panel } from 'primereact/panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faAt } from '@fortawesome/free-solid-svg-icons'

const ContactPanel = () => {
    return (
        <div className={styles.contactWrapper}>
            <Panel>
                <p>
                    <FontAwesomeIcon icon={faMobileAlt} />
                +420 739 993 242
        </p>
                <p>
                    <FontAwesomeIcon icon={faAt} />
                band@easysteps.cz
        </p>
            </Panel>
        </div>
    );
}

export default ContactPanel;