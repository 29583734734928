import React from 'react'
import { render } from 'react-dom';
import './index.scss'
import App from './App'
import { initFacebookSdk } from './service/fb/fb'
import * as serviceWorker from './serviceWorker'

initFacebookSdk().then(startApp);

function startApp() {
  render(
    <App />,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
