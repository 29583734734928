import React, { useReducer, forwardRef, useImperativeHandle } from 'react'

import Input from '../../components/UI/input/input'

import styles from './form.module.scss'

const Form = forwardRef((props, ref) => {
    const formReducer = (state, event) => {
        if (event.reset) {
            return emptyFormData();
        }
        return {
            ...state,
            [event.name]: {
                value: event.value,
                valid: event.valid,
                validationMessage: event.validationMessage,
                touched: true
            }
        }
    }

    const emptyFormData = () => {
        var data = {}
        Object.keys(props.config).forEach(key => {
            data[key] = {
                value: getEmptyValue(key),
                valid: 'true',
                touched: 'false',
                validationMessage: ''
            };
        })

        return data
    }

    const getEmptyValue = key => {
        if (props.config[key].elementType === 'checkbox') {
            return false
        }
        if (props.config[key].elementConfig.datatype === 'number') {
            return 0
        }
        return ''
    }

    const [formData, setFormData] = useReducer(formReducer, props.formData ? props.formData : emptyFormData())

    const checkValidity = (value, rules) => {
        if (rules) {
            if (rules.required) {
                if (value == null) {
                    return [false, rules.required.error]
                } else if (typeof value === 'string' && value.trim() === '') {
                    return [false, rules.required.error]
                }
            }

            if (rules.email) {
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                    return [false, rules.email.error]
                }
            }
        }

        return [true, '']
    }

    const validateAll = () => {
        var allValid = true;
        Object.keys(props.config).forEach(
            key => {
                const isCheckbox = props.config[key].elementType === 'checkbox';
                const validation = isCheckbox ? [true, ''] : checkValidity(formData[key].value, props.config[key].validation)
                allValid = allValid && validation[0];
                setFormData({
                    name: key,
                    value: formData[key].value,
                    valid: validation[0],
                    validationMessage: validation[1]
                })
            }
        )

        return allValid
    }

    const handleChange = (event, elementKey) => {
        const isCheckbox = props.config[elementKey].elementType === 'checkbox'
        const validation = isCheckbox ? [true, ''] : checkValidity(event.target.value, props.config[elementKey].validation)

        const value = isCheckbox ? event.target.checked : event.target.value

        setFormData({
            name: event.target.id,
            value: value,
            valid: validation[0],
            validationMessage: validation[1]
        })

        if (props.onChange) {
            props.onChange(event, elementKey)
        }

    }

    const showSuccess = msg => {
        if (props.onSuccess) {
            props.onSuccess(msg)
        }
    }

    const showError = msg => {
        if (props.onFailure) {
            props.onFailure(msg)
        }
    }

    const handleSubmit = () => {
        if (!validateAll()) {
            return Promise.reject()
        }

        return props.onSubmit(formData)
            .then(
                result => {
                    showSuccess(result.message)
                    setFormData({ reset: true })
                },
                error => {
                    showError(error.message)
                })
    }

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            return handleSubmit()
        },

        setValue: (key, value) => {
            formData[key].value = value
        }
    }))

    return (
        <div className={styles.formWrapper}>
            <div className="p-fluid">
                {
                    Object.keys(props.config).map(key => {
                        return (<Input
                            elementType={props.config[key].elementType}
                            {...props.config[key].elementConfig}
                            id={key}
                            key={key}
                            onChange={event => handleChange(event, key)}
                            disabled={props.submitting}
                            value={formData[key].value}
                            valid={formData[key].valid || !formData[key].touched}
                            error={formData[key].validationMessage}
                        />
                        )
                    })
                }
            </div >
        </div>
    )
})

export default Form;