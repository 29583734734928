import { createContext, useContext } from 'react'
import { callApiGet, callApiPost, callApiPut, callApiDelete, getQueryParams } from './api'


const getGallery = (params = {}) => {
    return callApiGet("/gallery/", getQueryParams(params))
}

const deleteImage = (id) => {
    return callApiDelete("/gallery/" + id)
}

const createImage = (image) => {
    return callApiPost("/gallery/", image)
}


const updateImage = (image) => {
    return callApiPut("/gallery/" + image.id, image)
}

const GalleryApiContext = createContext({
    getAll: getGallery,
    delete: deleteImage,
    create: createImage,
    update: updateImage
})

export const useGalleryApi = () => {
    return useContext(GalleryApiContext)
}

export default useGalleryApi