import React from 'react';
import PropTypes from 'prop-types';

import styles from './lineup.module.scss';

const Lineup = (props) => {
    var lineup = JSON.parse(props.lineupJSON).items;

    const itemsArr = lineup.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.time}</td>
                <td className={item.time ? "p-pl-4" : ""}>{item.item}</td>
            </tr>)
    })

    return (<table cellSpacing="0" className={styles.lineup}><tbody>{itemsArr}</tbody></table>);
}

Lineup.propTypes = {
    lineupJSON: PropTypes.string.isRequired
};

export default Lineup;
