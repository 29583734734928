import React from 'react';
import styles from './times.module.scss';

import UnitUtils from '../../../../utils/UnitUtils';

const times = (props) => {
    var size = 30;
    var unit = 'px';
    if (props.size) {
        [size, unit] = UnitUtils.parseSize(props.size);
    }

    var wrapperStyle = { width: size + unit, height: size + unit };
    var lineStyle = {
        width: size + unit,
        marginTop: (size / 2) + unit,
        height: (size / 10) + unit,
        borderRadius: (size / 10) + unit
    };

    return (
        <div onClick={props.onClick} className={styles.timesWrapper} style={wrapperStyle}>
            <div className={styles.line + " " + styles.leftRight} style={lineStyle}></div>
            <div className={styles.line + " " + styles.rightLeft} style={lineStyle}></div>
        </div>
    );
}

export default times;