import { useHistory } from 'react-router-dom'
import React from 'react'
import { Auth } from '../../../service/auth/auth'
import LoadingScreen from '../../loadingScreen/loadingScreen'


const Logout = props => {
    const history = useHistory()
    Auth.logout().then(() =>
        history.push("/login")
    )

    return (<LoadingScreen />)
}

export default Logout