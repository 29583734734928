import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './link.module.scss';

const link = (props) => {
    return (
        <NavLink to={props.link} className={styles.link} onClick={props.onClick}>
            <div >{props.children}</div>
        </NavLink>
    );
}

export default link;