import React from 'react'
import styles from './none.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const None = props => {
    return (
        <div className={styles.wrapper}>
            <FontAwesomeIcon icon={faTimesCircle} />
            <h1>Zatím nemáš přidělená žádná práva</h1>
            <div>Napiš panu nejvyššímu, ať s tím něco udělá</div>
        </div>
    )
}

export default None