import React from 'react';
import styles from './member.module.scss';
import dust from '../../assets/images/dust.png';

const Member = (props) => {
    var wrapperStyle = "p-col-12 p-sm-6 " + styles.memberWrapper
    if (props.inverse) wrapperStyle += " " + styles.inverse;

    var overlayStyle = [styles.overlay, "p-p-3", "p-flex-column", "p-jc-center", "p-d-flex"];
    if (props.visible) {
        overlayStyle.push(styles.visible)
    }


    return (
        <React.Fragment>
            <div className={wrapperStyle} onClick={props.onClick}>
                <div className={styles.member}>
                    <img alt={props.alt} src={props.img} />
                </div>
                <div className={styles.foreground}>
                    <img alt="" src={dust} />
                </div>
                <div className={overlayStyle.join(" ")}>
                    <h1>{props.header}</h1>
                    {props.content}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Member;