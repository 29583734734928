class UnitUtils {

    static parseSize(size) {
        const regex = /(\d+(?:\.\d+)?)([a-z%]+)/g;
        const found = regex.exec(size);

        if (found && found.length === 3) {
            return [found[1], found[2]];
        } else {
            throw new Error("Cannot parse size " + size);
        }
    }
}

export default UnitUtils;