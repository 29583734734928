import { createContext, useContext } from 'react'
import { callApiGet, callApiPost, callApiPut, callApiDelete, getQueryParams } from './api'


const getAllLocations = (params = {}) => {
    return callApiGet("/locations", getQueryParams(params))
}

const createLocation = location => {
    return callApiPost("/locations", location)
}

const updateLocation = location => {
    return callApiPut("/locations/" + location.id, location)
}

const deleteLocation = id => {
    return callApiDelete("/locations/" + id)
}

const LocationsApiContext = createContext({
    getAll: getAllLocations,
    delete: deleteLocation,
    create: createLocation,
    update: updateLocation
})

const useLocationsApi = () => {
    return useContext(LocationsApiContext)
}

export default useLocationsApi