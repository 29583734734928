import React, { useState, useEffect, useRef } from 'react'
import styles from './App.module.scss'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import GuardedRoute from './components/hoc/guardedRoute/guardedRoute'
import AuthorizedRoute from './components/hoc/authorizedRoute/authorizedRoute'
import { Auth, ROLES } from './service/auth/auth'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Index from './components/index/index'
import Gigs from './components/gigs/gigs'
import Gallery from './components/gallery/gallery'
import Band from './containers/band/band'
import Contact from './containers/contact/contact'

import Error from './components/error/error'

import Snow from './components/snow/snow'

import AdminNavbar from './components/admin/navbar/navbar'
// import AdminBlog from './components/admin/blog/blog'
import AdminGallery from './components/admin/gallery/gallery'
import AdminUsers from './components/admin/users/users'
import AdminGigs from './components/admin/gigs/gigs'
import AdminLocations from './components/admin/locations/locations'
import AdminNone from './components/admin/none/none'
import Login from './components/admin/login/login'
import Logout from './components/admin/logout/logout'

import Navbar from './components/navbar/navbar'

import './theme/es-mdc-dark/theme.scss'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import PrimeReact from 'primereact/api'
import Sidebar from './components/sidebar/sidebar'
import Footer from './components/footer/footer'
import Overlay from './components/overlay/overlay'

import LoadingScreen from './components/loadingScreen/loadingScreen'

import ScrollToTop from './components/hoc/scrollToTop/scrollToTop'
import Dummy from './components/dummy/dummy'

PrimeReact.ripple = true

const App = props => {
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [loading, setLoading] = useState(true)

  const sidebarRef = useRef(null)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
    return () => { clearAllBodyScrollLocks(); }
  }, [])


  const toggleSidebar = visible => {
    setSidebarVisible(visible);

    if (visible) {
      disableBodyScroll(sidebarRef.current)
    } else {
      console.log("Enabling scroll")
      enableBodyScroll(sidebarRef.current)
    }
  }

  const Admin = ({ match }) => {
    var defaultPath
    if (Auth.isRole(ROLES.GIGS_ADMIN)) {
      defaultPath = "/koncerty"
    } else if (Auth.isRole(ROLES.GALLERY_ADMIN)) {
      defaultPath = "/galerie"
    }
    // else if (Auth.isRole(ROLES.BLOG_ADMIN)) {
    //   defaultPath = "/blog"
    // } 
    else {
      defaultPath = "/403"
    }

    return (
      <div style={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}>
        <AdminNavbar />
        <Switch>
          <AuthorizedRoute path={`${match.url}/koncerty`} exact component={AdminGigs} role={ROLES.GIGS_ADMIN} />
          <AuthorizedRoute path={`${match.url}/mista`} exact component={AdminLocations} role={ROLES.GIGS_ADMIN} />
          <AuthorizedRoute path={`${match.url}/galerie`} exact component={AdminGallery} role={ROLES.GALLERY_ADMIN} />
          <AuthorizedRoute path={`${match.url}/borci`} exact component={AdminUsers} role={ROLES.SU} />
          {/* <AuthorizedRoute path={`${match.url}/blog`} exact component={AdminBlog} role={ROLES.BLOG} /> */}
          <Route path={`${match.url}/403`} exact component={AdminNone} />
          <Route path={`${match.url}`} >
            <Redirect to={`${match.url}${defaultPath}`} />
          </Route>
        </Switch>
      </div>
    )
  }

  const Errors = ({ match }) => {
    return (
      <Switch>
        <Route path={`${match.url}/:errcode`} exact component={Error}></Route>
        <Redirect to={`${match.url}/404`} />
      </Switch>
    )
  }

  return (
    <div>
      <Snow />
      {!loading ? (
        <div className={styles.app}>
          <BrowserRouter>
            <Switch>
              <Route path="/vymazani-osobnich-dat" component={Dummy} />
              <Route path="/ochrana-soukromi" component={Dummy} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <GuardedRoute path='/admin' component={Admin} />
              <Route path="/error" component={Errors} />
              <Route path="/">
                <div>
                  <ScrollToTop />
                  <div>
                    <Navbar onMenuClick={() => toggleSidebar(true)}></Navbar>
                    <Overlay visible={sidebarVisible} click={() => toggleSidebar(false)} />
                    <Sidebar ref={sidebarRef} onMenuClose={() => toggleSidebar(false)} visible={sidebarVisible}></Sidebar>
                    <Switch>
                      <Route path="/kapela" component={Band}></Route>
                      <Route path="/kontakt" component={Contact}></Route>
                      <Route path="/galerie" component={Gallery}></Route>
                      <Route path="/koncerty" component={Gigs}></Route>
                      <Route path="/" exact component={Index}></Route>
                      <Redirect to='/error/404' />
                    </Switch>
                  </div>
                  <div>
                    <Footer />
                  </div>
                </div>
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
      ) : (<LoadingScreen />)
      }
    </div>
  );
}

export default App
