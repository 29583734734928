import React from 'react'
import styles from './error.module.scss'
import { Link, useParams } from 'react-router-dom'

import logo from '../../assets/vectors/logo/es_logo_name_white.svg'

const Error = props => {
    var { errcode } = useParams()

    if (typeof errcode === 'undefined') {
        errcode = 500
    }

    var errorMessage = errorCodes[parseInt(errcode)]

    if (typeof errorMessage === 'undefined') {
        errorMessage = 'Neznámá Chyba'
    }

    return (
        <div className={styles.wrapper}>
            <Link to="/" className={styles.link}>
                <img alt="Logo" src={logo} />
            </Link>
            <div className={styles.error}>
                {errcode} {errorMessage.toUpperCase()}
            </div>
        </div>
    )
}

const errorCodes = {
    100: 'Pokračovat',
    101: 'Přepnutí Protokolu',
    102: 'Zpracovávání',
    103: 'Early Hints',
    200: 'OK',
    201: 'Vytvořeno',
    202: 'Přijato',
    203: 'Non-Authoritative Information',
    204: 'Žádný Obsah',
    205: 'Reset Obsahu',
    206: 'Částečný Obsah',
    207: 'Multi-Status',
    208: 'Already Reported',
    226: 'IM Used',
    300: 'Multiple Choices',
    301: 'Permanentně Přesunuto',
    302: 'Nalezeon',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    306: 'Switch Proxy',
    307: 'Dočasné Přesměrování',
    308: 'Permanentní Přesměrování',
    400: 'Špatný Požadavek',
    401: 'Neautorizovaný Požadavek',
    402: 'Platba Požadována',
    403: 'Přístup Odepřen',
    404: 'Stránka Nenalezena',
    405: 'Metoda Nepovolena',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'URI Too Long',
    415: 'Nepodporovaný Typ Media',
    416: 'Range Not Satisfiable',
    417: 'Expectation Failed',
    418: 'I\'m a teapot',
    421: 'Misdirected Request',
    422: 'Unprocessable Entity',
    423: 'Locked',
    424: 'Failed Dependency',
    425: 'Too Early',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Příliš Mnoho Požadavků',
    431: 'Request Header Fields Too Large',
    451: 'Unavailable For Legal Reasons',
    500: 'Interní Chyba Serveru',
    502: 'Bad Gateway',
    503: 'Služba Nedostupná',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates',
    507: 'Insufficient Storage',
    508: 'Loop Detected',
    510: 'Not Extended',
    511: 'Network Authentication Required'
}

export default Error