import { createContext, useContext } from 'react'
import { callApiGet, callApiPost, callApiPut, callApiDelete, getQueryParams } from './api'

const getUpcomingGigs = (params = {}) => {
    return callApiGet("/gigs/upcoming/", getQueryParams(params))
}

const getPassedGigs = (params = {}) => {
    return callApiGet("/gigs/passed/", getQueryParams(params))
}

const getAllGigs = (params = {}) => {
    return callApiGet("/gigs/", getQueryParams(params))
}

const createGig = (gig) => {
    return callApiPost("/gigs/", gig)
}

const deleteGig = (id) => {
    return callApiDelete("/gigs/" + id)
}

const updateGig = (gig) => {
    return callApiPut("/gigs/" + gig.id, gig)
}

const GigsApiContext = createContext({
    getUpcomingGigs: getUpcomingGigs,
    getPassedGigs: getPassedGigs,
    getAll: getAllGigs,
    update: updateGig,
    create: createGig,
    delete: deleteGig
})

const useGigsApi = () => {
    return useContext(GigsApiContext)
}

export default useGigsApi