import React, { useRef, useState } from 'react'
import useGoogleMaps from '../../utils/useGoogleMaps'
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api'
import { InputText } from 'primereact/inputtext'


const Map = (props) => {
    const [loaded, error] = useGoogleMaps()
    const mapRef = useRef(null)
    const [marker, setMarker] = useState(null)
    const [sb, setSb] = useState(null)
    const [bounds, setBounds] = useState(null)
    const [center, setCenter] = useState({
        lat: props.lat,
        lng: props.lng
    })


    if (!loaded) {
        return <div>Loading map...</div>
    } else if (error) {
        return <div>{error}</div>
    }

    function handleLoad(map) {
        mapRef.current = map
        if (props.marker) {
            setMarker(mapRef.current.getCenter().toJSON())
        }
    }

    const centerChanged = () => {
        if (!mapRef.current || !props.onCenterChanged) return

        props.onCenterChanged(mapRef.current.getCenter().toJSON())
    }

    const zoomChanged = () => {
        if (!mapRef.current || !props.onZoomChanged) return

        props.onZoomChanged(mapRef.current.getZoom())
    }

    const boundsChanged = () => {
        if (mapRef.current) {

        }
        setBounds(mapRef.current.getBounds())
    }

    const onSBLoad = (searchBox) => {
        setSb(searchBox)
    }

    const onPlacesChanged = () => {
        if (sb !== null) {
            const places = sb.getPlaces()

            if (places.length === 0) {
                return;
            }

            const place = places[0]

            if (!place.geometry || !place.geometry.location) {
                return
            }

            setMarker(place.geometry.location)
            setCenter(place.geometry.location)

        }
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {props.search &&
                <div id="searchbox">
                    <StandaloneSearchBox
                        onLoad={onSBLoad}
                        onPlacesChanged={onPlacesChanged}
                        bounds={bounds}
                    >
                        <InputText
                            type="text"
                            placeholder="Hledej..."
                            style={{
                                boxSizing: `border-box`,
                                width: `240px`,
                                height: `32px`,
                                padding: `0 12px`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                zIndex: 100,
                                left: "50%",
                                marginLeft: "-120px",
                                marginTop: "-40px"
                            }}
                        />
                    </StandaloneSearchBox>
                </div>
            }
            <GoogleMap
                onLoad={handleLoad}
                mapContainerStyle={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%'
                }}
                onCenterChanged={centerChanged}
                onZoomChanged={zoomChanged}
                onBoundsChanged={boundsChanged}
                center={center}
                zoom={props.zoom}
            >
                <Marker position={marker} animation="BOUNCE" />
            </GoogleMap>
        </div>)

}

export default Map;